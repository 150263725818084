import TableHeaderHtmlCustom from '../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../components/ui';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  background: '#FFF2CC',
  color: '#343a40',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    { text: 'NO. Of Customers', style: style1 },
    { text: 'Value Of Business', style: style1 },
    { text: 'Remarks', style: style1 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Business Lost Due to Poor Quality ${year}-${
          year && year + 1
        }`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
