import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/UseQuery';
import CustomSelect from './SelectField';

interface option {
  name: string | number;
  value: string | number;
}
interface IProps {
  options: option[];
}

const FilterBy = ({ options }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const filter = useQuery('filter');
  const handleChange = (e: SelectChangeEvent) => {
    const parsedQuery = queryString.parse(location.search);
    parsedQuery.filter = e.target.value;
    const query = queryString.stringify(parsedQuery);
    navigate(`${location.pathname}?${query}`);
  };
  return (
    <CustomSelect
      name="filter"
      onChange={(e: any) => handleChange(e)}
      label="Filter"
      value={filter}
      variant="filled"
    >
      <MenuItem value="">None</MenuItem>
      {options.map((option) => (
        <MenuItem value={option.value} key={option.name}>
          {option.name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default FilterBy;
