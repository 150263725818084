import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useQuery } from '../../../../hooks/UseQuery';
import { allMonths } from '../../../../utils/helpers/allMonths';
import { useAuth } from '../../../../services/auth/AuthProvider';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  item: any;
  isView: boolean;
  getFullYear: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
  isView,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    moduleData[item][e.target.name] = e.target.value;
  };
  //  background: '#FFF2CC'
  return (
    <>
      <tr>
        <td style={{ textAlign: 'start' }}>{state.user?.zone}</td>
        <td style={{ minWidth: '170px', textAlign: 'start' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="name_of_employee"
            value={moduleData?.[item]?.name_of_employee || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="things_went_right_achievement"
            value={moduleData?.[item]?.things_went_right_achievement || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={moduleData?.[item]?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        {/*  */}
      </tr>
    </>
  );
};

export default TBodyRow;
