const keyPropery = {
  target_no_of_customer: null,
  achieved_no_of_customer: null,
  achieved_percentage: null,
  qlty_25: null,
  cost_5: null,
  delivery_35: null,
  technical_commercial_assistance_10: null,
  management_25: null,
  avg_score_of_total_customers: null,
};

export const initialModuleState = {
  april: { ...keyPropery },
  may: { ...keyPropery },
  june: { ...keyPropery },
  july: { ...keyPropery },
  august: { ...keyPropery },
  september: { ...keyPropery },
  october: { ...keyPropery },
  november: { ...keyPropery },
  december: { ...keyPropery },
  january: { ...keyPropery },
  february: { ...keyPropery },
  march: { ...keyPropery },

  TOTAL_target_no_of_customer: null,
  TOTAL_achieved_no_of_customer: null,
  TOTAL_achieved_percentage: null,
  TOTAL_qlty_25: null,
  TOTAL_cost_5: null,
  TOTAL_delivery_35: null,
  TOTAL_technical_commercial_assistance_10: null,
  TOTAL_management_25: null,
  TOTAL_avg_score_of_total_customers: null,
};
