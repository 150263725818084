import { allMonths } from '../../../utils/helpers/allMonths';

export class ModuleDataHandler {
  moduleData: any;

  constructor(moduleData: any) {
    this.moduleData = moduleData;
  }

  calculatePercentage(month: string) {
    const { target_no_of_customer = 1, achieved_no_of_customer = 0 } =
      this.getListData(month);

    this.updateListProperty(
      month,
      'achieved_percentage',
      (Number(achieved_no_of_customer) / Number(target_no_of_customer)) * 100
    );

    this.calculateTotal('target_no_of_customer', 'TOTAL_target_no_of_customer');

    this.calculateTotal(
      'achieved_no_of_customer',
      'TOTAL_achieved_no_of_customer'
    );

    this.calculateTotal('achieved_percentage', 'TOTAL_achieved_percentage');
  }

  // all totals function
  calculateTotal(key_name: any, update_key_name: any) {
    const total = allMonths.reduce((accumulator: any, month: any) => {
      if (
        this.moduleData.hasOwnProperty(month) &&
        this.moduleData[month].hasOwnProperty(key_name)
      ) {
        return (+accumulator || 0) + +this.moduleData[month][key_name];
      }
      return accumulator;
    }, 0);
    this.moduleData[update_key_name] = total;
  }

  caclTotalArray(month: string, key_name: string, update_key_name: string) {
    const total = this.moduleData[month]?.[key_name]?.reduce(
      (accumalator: any, currentValue: any) => {
        if (currentValue) {
          return (+accumalator || 0) + (+currentValue || 0);
        }
        return accumalator;
      }
    );
    this.updateListProperty(month, update_key_name, total);
  }

  private getListData(month: string) {
    if (!this.moduleData[month]) {
      this.moduleData[month] = {};
    }
    return this.moduleData[month];
  }

  private updateListProperty(month: string, property: string, value: any) {
    this.moduleData[month][property] = value;
  }
}
