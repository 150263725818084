import { useEffect, useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { allMonths } from '../../../../utils/helpers/allMonths';
import { useAuth } from '../../../../services/auth/AuthProvider';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../components/common/formatDate';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  isView: any;
  handleDelete: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  isView,
  handleDelete,
}: IProps) => {
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  useEffect(() => {
    if (!item.comitted_date || !item.material_received_date) {
      item.delays = null;
      return;
    }
    const startDate = moment(item?.comitted_date);
    const endDate = moment(item?.material_received_date);
    const duration = moment.duration(endDate.diff(startDate));

    item.delays = Math.round(duration.asDays());
    setRender((prev) => !prev);
  }, [item.comitted_date, item.material_received_date]);

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{item?.zone}</td>
        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="month"
            label=""
            value={item?.month}
            sx={{
              textAlign: 'center',
              width: '140px',
            }}
            onChange={(e) => {
              item.month = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            {allMonths?.map((month) => (
              <MenuItem value={month}>{month?.toLocaleUpperCase()}</MenuItem>
            ))}
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="failure_department"
            label=""
            value={item?.failure_department}
            sx={{
              textAlign: 'center',
              width: '160px',
            }}
            onChange={(e) => {
              item.failure_department = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Production">Production</MenuItem>
            <MenuItem value="Purchase">Purchase</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput isView={true} type="text" value={state?.user?.name} />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="so"
            value={item?.so || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="customer"
            style={{ minWidth: '113px' }}
            value={item?.customer || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="part_no"
            value={item?.part_no || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="qty"
            value={item?.qty || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center', minWidth: '150px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.comitted_date = moment(date);
              setRowRender((prev) => !prev);
            }}
            value={(item?.comitted_date && moment(item?.comitted_date)) || null}
          />
        </td>

        <td style={{ textAlign: 'center', minWidth: '150px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.material_received_date = moment(date || null);
              setRowRender((prev) => !prev);
            }}
            value={
              (item?.material_received_date &&
                moment(item?.material_received_date)) ||
              null
            }
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="delays"
            value={item?.delays}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            style={{ minWidth: '113px' }}
            value={item?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <DeleteIconButton
            tooltipTitle="DELETE"
            onClick={() => {
              handleDelete(index);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
