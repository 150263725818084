import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';

import { UpdateButton } from '../../components/common/button/CustomButtons';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { PersitedDatePicker } from '../../components/common';
import { Loader } from '../../components/common/Loader';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  DialogSync: any;
  filterData: any;
  empData: any;
  setFilterData: any;
  confirmSync: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
}

const Create = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const isView = useQuery('isView') === 'true' ? true : false;
  const {
    moduleData,
    isLoading,
    DialogSync,
    confirmSync,
    error,
    filterData,
    setFilterData,
    empData,
    setRender,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    if (!date) {
      alert('date required');
      return;
    }

    if (moduleData._id) {
      const ans = await confirmSync();
      if (!ans) return;
      axios
        .put(`/api/customerFeedbackRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    } else {
      const ans = await confirmSync();
      if (!ans) return;
      const userid = filterData ? filterData?._id : state.user?._id;
      if (!userid) return;
      moduleData.employee_id = userid;
      moduleData.created_date = date;
      moduleData.year = new Date(date).getFullYear();
      axios
        .post(`/api/customerFeedbackRoute`, moduleData)
        .then((res) => {
          alert('successfully created');
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <Box>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            pl: 2,
            pb: 1,
            pt: 2,
            gap: 2,
          }}
        >
          <PersitedDatePicker label="Search By Year" views={['year']} />
          <UpdateButton
            label="update"
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              handleUpdate();
            }}
          />
        </Stack>
      </Box>

      <Loader loader={isLoading} />

      <TController
        {...{
          moduleData,
          isView,
        }}
      />

      <DialogSync isSubmitContent={false} />
    </Box>
  );
};

export default Create;
