import { useState } from 'react';
import TBodyRow from './TBodyRow';
import { useQuery } from '../../../hooks/UseQuery';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { allMonths } from '../../../utils/helpers/allMonths';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear().toString().slice(-2);
  const getFullYear = date && new Date(date).getFullYear().toString();
  const dataHandler = new ModuleDataHandler(moduleData);
  dataHandler.calculateTotal('target', 'total_target');

  return (
    <tbody>
      {allMonths.map((item: any, index: number) => (
        <TBodyRow
          {...{ moduleData, item, index, setRender, year, getFullYear }}
        />
      ))}

      <tr>
        <th colSpan={2}>{'TOTAL'}</th>
        <th>{moduleData.TOTAL_target_no_of_customer}</th>
        <th>{moduleData.TOTAL_achieved_no_of_customer}</th>
        <th>{moduleData.TOTAL_achieved_percentage}</th>
        <th>{moduleData.TOTAL_qlty_25}</th>
        <th>{moduleData.TOTAL_cost_5}</th>
        <th>{moduleData.TOTAL_delivery_35}</th>
        <th>{moduleData.TOTAL_technical_commercial_assistance_10}</th>
        <th>{moduleData.TOTAL_management_25}</th>
        <th>{moduleData.TOTAL_avg_score_of_total_customers}</th>
      </tr>
    </tbody>
  );
};

export default TBody;
