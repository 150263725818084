interface actionType {
  type: string;
  user?: {};
  error?: string;
}

interface Iuser {
  userType?: number;
}
export interface authState {
  readonly user: Iuser | null;
  readonly error: {} | null | string;
  readonly loading: boolean;
  readonly IS: {
    HOD: 1;
    RSM: 2;
    SE: 3;
  };
}

interface ActionHandlers {
  [key: string]: (state: authState, action: actionType) => authState;
}

const authReducer = (state: authState, action: actionType) => {
  const actionHandlers: ActionHandlers = {
    LOGOUT: () => ({
      ...state,
      user: null,
      loading: false,
    }),
    LOGIN: () => ({
      ...state,
      user: action.user!,
      loading: false,
      error: null,
      IS: {
        HOD: 1,
        RSM: 2,
        SE: 3,
      },
    }),
    ERROR: () => ({
      ...state,
      error: action.error!,
      loading: false,
    }),
    default: () => state,
  };

  const handler = actionHandlers[action?.type] || actionHandlers.default;
  return handler(state, action);
};

export default authReducer;
