export const ToggleButtonMap = [
  {
    value: 'add',
    text_1: 'Purchase Feasibility',
    text_2: 'Dashboard',
  },
  {
    value: 'dashboard',
    text_1: 'Purchase Feasibility Dashboard',
  },
];

export const navigationMap: any = {
  dashboard: '/se/purchaseFeasibilityDashboard',
  add: '/se/addPurchaseFeasibility',
};

export const apiConstantAddParts = {
  delete: `/api/addPurchaseFeasibilityRoute`,
  update: `/api/addPurchaseFeasibilityRoute`,
  get: `/api/addPurchaseFeasibilityRoute`,
  post: `/api/addPurchaseFeasibilityRoute`,
};

export const apiConstantDashboard = {
  delete: `/api/purchaseFeasibilityDashboardRoute`,
  update: `/api/purchaseFeasibilityDashboardRoute`,
  get: `/api/purchaseFeasibilityDashboardRoute`,
  post: `/api/purchaseFeasibilityDashboardRoute`,
};
