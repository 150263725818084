import RSMHome from '../pages/HomeRsm/RSMHome';
import { RsmAnnulOverview } from '../pages/annualOvervieweRSM';
import { RSMSetTargetList } from '../pages/rmsSettarget';
import { Profile } from '../pages/profile';
import { PremiumFreight } from '../pages/premiumFreight';
import { Kaizen } from '../pages/kaizen';
import { BizLostDueToPoorQlty } from '../pages/bizLostDueToPoorQlty';
import { ThingsWentRightAndWrong } from '../pages/thingsWentRightAndWrong';
import { RightThings } from '../pages/thingsWentRightAndWrong/RightThings';
import { WrongThings } from '../pages/thingsWentRightAndWrong/WrongThings';
import { OrderCancellation } from '../pages/orderCancellation';

export const IndexRSM = [
  {
    path: 'home',
    element: <RSMHome />,
  },
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'rsmSetTargetList',
    element: <RSMSetTargetList />,
  },
  {
    path: 'RSMAnnulOverview',
    element: <RsmAnnulOverview />,
  },
  {
    path: 'premiumFreight',
    element: <PremiumFreight />,
  },
  {
    path: 'kaizen',
    element: <Kaizen />,
  },
  {
    path: 'seBizLostDueToPoorQlty',
    element: <BizLostDueToPoorQlty />,
  },

  {
    path: 'thingsWentRightAndWrong',
    element: <ThingsWentRightAndWrong />,
  },
  {
    path: 'wrongThings',
    element: <WrongThings />,
  },
  {
    path: 'rightThings',
    element: <RightThings />,
  },
  {
    path: 'orderCancellation',
    element: <OrderCancellation />,
  },
];
