import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    { text: 'Failure Department', style: { ...style1 } },
    { text: 'Sale Employee', style: style1 },
    { text: 'SO', style: style1 },
    { text: 'Customer', style: style1 },
    { text: 'Part No.', style: style1 },
    { text: 'Qty', style: style1 },
    { text: 'Comitted Date', style: style1 },
    { text: 'Material Received Date', style: style1 },
    { text: 'Delays (No. of Days)', style: style1 },
    { text: 'Remarks', style: style1, colspan: 2 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
