import styled from 'styled-components';

interface IHomeContainer {
  justify?: string;
  height?: string;
  columns?: string;
}
export const HomeContainer = styled.main<IHomeContainer>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, ${(props) => props.columns || '1fr'})
  );
  gap: 3.2rem;
  padding: 4.8rem;
  height: ${(props) => props.height || 'auto'};
  font-size: 1rem;
  font-family: 'roboto';
  background-color: #fafafa;
  justify-items: ${(props) => props.justify || 'center'};
  z div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-weight: 600;
    }
  }

  & .panel {
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #94949454; */
    color: rgba(0, 0, 0, 0.733) !important;
    text-decoration: none !important;
    margin-top: 1rem;
    width: 150px;
    height: 150px;
    border-radius: 40px;
    background: #fafafa;
    box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
    .numberingIcon {
      font-weight: 600;
      font-size: 4.8rem;
      color: #003566;
      /* color: black; */
    }
  }
  & .panel:hover {
    background-color: #0342421f;
    transition: background-color 0.4s ease-in-out;
  }

  & p.card-btn-para {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  & .card-btn-span {
    display: block;
    font-weight: 400;
  }
`;
