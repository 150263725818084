import { useState } from 'react';
import TBodyRow from './TBodyRow';
import { useQuery } from '../../../hooks/UseQuery';
import { allMonths } from '../../../utils/helpers/allMonths';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear().toString().slice(-2);
  const getFullYear = date && new Date(date).getFullYear().toString();

  return (
    <tbody>
      {moduleData &&
        allMonths.map((item: any, index: number) => (
          <TBodyRow
            {...{ moduleData, item, index, setRender, year, getFullYear }}
          />
        ))}
      <tr>
        <th colSpan={3}>{'TOTAL'}</th>
        <th>{moduleData.total_target}</th>
        <th>{moduleData.total_mtd_actual}</th>
        <th>{moduleData.total_mtd_ach_perc}%</th>
        <th>{moduleData.total_mtd_target_pending}</th>
        <th>{}</th>
        <th>{}</th>
        <th>{}</th>
      </tr>
    </tbody>
  );
};

export default TBody;
