import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../components/common/useConfirm';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import lodash from 'lodash';
import { initialModuleState } from './intial';

const useGetData = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const date = useQuery('date');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [filterData, setFilterData] = useState<any>({});
  const { state } = useAuth();
  const [DialogSync, confirmSync] = useConfirm(
    'Update Confirmation',
    'Are you sure you want to update?'
  );

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(`/api/monthlyDeliveryFailureRoute/${date}/${state.user?._id}`)
        .then((response) => {
          setModuleData(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData(lodash.cloneDeep(initialModuleState));
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date]);

  return {
    moduleData,
    isLoading,
    DialogSync,
    filterData,
    setFilterData,
    empData,
    confirmSync,
    error,
    setRender,
  };
};

export default useGetData;
