import { Box, MenuItem, Paper, Stack, Tab, Tabs } from '@mui/material';
import { useAuth } from '../../services/auth/AuthProvider';
import CustomSelect from '../../components/common/SelectField';
import { BsUnlockFill, BsLockFill } from 'react-icons/bs';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import { UpdateButton } from '../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../components/common';

const HeaderCreate = ({
  empData,
  filterData,
  setFilterData,
  islock,
  setIsLock,
  setisView,
  setRender,
  handleUpdate,
}: any) => {
  const { state } = useAuth();

  return (
    <>
      <Box>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            pl: 2,
            pb: 1,
            pt: 2,
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: 'start',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <PersitedDatePicker label="Search By Year" views={['year']} />
            {(state.user?.userType === state.IS.RSM ||
              state.user?.userType === state.IS.HOD) && (
              <>
                <CustomSelect
                  name="remarks"
                  label="Zone"
                  value={state?.user?.zone}
                  disabled={
                    state.user?.userType === state.IS.HOD ? false : true
                  }
                  sx={{
                    textAlign: 'center',
                    width: '200px',
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="NORTH">NORTH</MenuItem>
                  <MenuItem value="EAST">EAST</MenuItem>
                  <MenuItem value="WEST">WEST</MenuItem>
                  <MenuItem value="SOUTH">SOUTH</MenuItem>
                </CustomSelect>

                <AutocompleteMuiCustom
                  sx={{
                    width: '200px',
                  }}
                  label={state.user?.userType === state.IS.HOD ? 'RSM' : 'SE'}
                  id={'name'}
                  option_name={'name'}
                  value={filterData || ''}
                  arrayofObj={empData}
                  onChange={(e, value: any) => {
                    setFilterData(value);
                    setIsLock(true);
                    value ? setisView(true) : setisView(false);
                    setRender((prev: any) => !prev);
                  }}
                />
              </>
            )}
            {filterData &&
              (state.user?.userType === state.IS.RSM ||
                state.user?.userType === state.IS.HOD) && (
                <>
                  {islock ? (
                    <BsLockFill
                      style={{
                        cursor: 'pointer',
                        color: '#e03131',
                      }}
                      size="3rem"
                      onDoubleClick={(e) => {
                        setIsLock(false);
                        setisView(false);
                      }}
                      onClick={(e) => e.preventDefault()}
                      title="Click to Unlock"
                    />
                  ) : (
                    <BsUnlockFill
                      style={{
                        cursor: 'pointer',
                        color: '#099268',
                      }}
                      size="3rem"
                      onDoubleClick={(e) => {
                        setIsLock(true);
                        setisView(true);
                      }}
                      onClick={(e) => e.preventDefault()}
                      title="Click to Lock"
                    />
                  )}
                </>
              )}
          </Stack>
          {(state.user?.userType === state.IS.RSM ||
            state.user?.userType === state.IS.SE) && (
            <UpdateButton
              label="update"
              sx={{ mr: '37px', fontSize: '2rem' }}
              size="large"
              onClick={() => {
                handleUpdate();
              }}
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default HeaderCreate;
