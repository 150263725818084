import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  HODProtectedRoute,
  RSMProtectedRoute,
  SEProtectedRoutes,
} from './utils/validators/ProtectedRoute';
import { IndexHOD } from './routes/IndexHOD';
import { IndexRSM } from './routes/IndexRSM';
import { IndexSE } from './routes/indexSE';
import ErrorPage from './error-page';
import Login from './pages/login/Login';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

//mendatory setting for adding color in palette
declare module '@mui/material/styles' {
  interface PaletteOptions {
    blueButton: PaletteOptions['primary'];
    greenButton: PaletteOptions['primary'];
    // Button: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#c80202',
    },
    secondary: {
      main: '#212529',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    text: {
      // primary: '#111',
      // secondary: '#f1f3f5',
    },
    blueButton: {
      main: '#003566',
      contrastText: '#fff',
    },
    greenButton: {
      main: '#064a29',
      contrastText: '#fff',
    },
  },
  typography: {
    // fontFamily: 'Raleway, Arial',
    fontSize: 22,
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/hod',
    element: <HODProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [...IndexHOD],
  },
  {
    path: '/rsm',
    element: <RSMProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [...IndexRSM],
  },
  {
    path: '/se',
    element: <SEProtectedRoutes />,
    errorElement: <ErrorPage />,
    children: [...IndexSE],
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
