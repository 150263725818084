import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#ffd8a8',
  color: '#343a40',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    { text: 'Total Numbers Credit Note', style: { ...style1 } },
    { text: 'Value', style: style1 },
    { text: 'Quality', style: style1 },
    { text: 'Customer', style: style1 },
    { text: 'Sales', style: style1 },
    { text: 'Production', style: style1 },
    { text: 'Others', style: style1 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Material Return Value ${year}-${year && year + 1}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
