import { RiTeamFill } from 'react-icons/ri';
import { PiTargetDuotone } from 'react-icons/pi';
import { FcPlanner } from 'react-icons/fc';
import { RenderTiles } from '../../components/common';
import { GiCargoCrate } from 'react-icons/gi';
import { RiCustomerService2Fill } from 'react-icons/ri';
import { MdAnchor, MdSelfImprovement } from 'react-icons/md';
import { TbBusinessplan } from 'react-icons/tb';
import { VscFeedback } from 'react-icons/vsc';
import { BsFillCartXFill, BsGraphUpArrow } from 'react-icons/bs';
import { TbTruckDelivery } from 'react-icons/tb';

const RSMTilesObj = [
  {
    sNo: 1,
    link: '/rsm/rsmSetTargetList',
    isLink: true,
    icon: <PiTargetDuotone size="5rem" color="#099268" />,
    isIcon: true,
    p: 'ASSIGN',
    span: 'TEAM TARGET',
  },
  {
    sNo: 1,
    link: '/rsm/RSMAnnulOverview',
    isLink: true,
    icon: <FcPlanner size="5rem" color="#099268" />,
    isIcon: true,
    p: 'ANNUAL',
    span: 'RSM SALES PLAN',
  },
  {
    sNo: 3,
    link: '/rsm/premiumFreight',
    isLink: true,
    icon: <GiCargoCrate size="5rem" color="#343a40" />,
    isIcon: true,
    p: 'Premium',
    span: 'Freight',
  },
  {
    sNo: 4,
    link: '/rsm/kaizen',
    isLink: true,
    icon: <MdSelfImprovement size="5rem" color="#343a40" />,
    isIcon: true,
    p: 'Kaizen',
    span: '',
  },
  {
    sNo: 9,
    link: '/rsm/seBizLostDueToPoorQlty',
    isLink: true,
    icon: <TbBusinessplan size="5rem" color="#f03e3e" />,
    isIcon: true,
    p: 'BUSINESS LOST',
    span: 'DUE TO POOR QLTY.',
  },
  {
    sNo: 10,
    link: '/rsm/thingsWentRightAndWrong',
    isLink: true,
    icon: <BsGraphUpArrow size="5rem" color="#099268" />,
    isIcon: true,
    p: 'THINGS WENT',
    span: 'RIGHT AND WRONG',
  },
  {
    sNo: 7,
    link: '/rsm/orderCancellation',
    isLink: true,
    icon: <BsFillCartXFill size="5rem" color="#f03e3e" />,
    isIcon: true,
    p: 'ORDER ',
    span: 'CANCELLATION',
  },
];

const RSMHome = () => {
  return (
    <RenderTiles
      tilesObj={RSMTilesObj}
      height="100vh"
      justify="start"
      columns="0fr"
    />
  );
};

export default RSMHome;
