import React, { useEffect, useRef, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import AlertMui from '../../components/common/AlertMui';
import { useAuth } from '../../services/auth/AuthProvider';

const Create = ({
  id,
  setIsModal,
  setModuleId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleId: any;
}) => {
  const [moduleData, setModuleData] = useState<any>({});
  const { state } = useAuth();
  const [render, setRender] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [alert, setAlert] = useState(false);
  const alertTextRef = useRef('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employee/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [id]);

  function handleModulData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    alertTextRef.current = '';
    const requireField = {
      name: 'Name',
      username: 'User Email',
      designation: 'Designation',
      zone: 'Zone',
    };
    // const requireField = ['name', 'username'];

    for (const [key, prop] of Object.entries(requireField)) {
      !moduleData[key] && (alertTextRef.current += `${prop.toUpperCase()}, `);
    }

    if (alertTextRef.current) {
      setAlert(true);
      return;
    }
    if (moduleData.designation === 'SE' && !moduleData.under_supervision) {
      alertTextRef.current += 'Under Supervision';
      setAlert(true);
      return;
    }
    if (moduleData.designation === 'RSM') {
      moduleData.under_supervision = state.user?._id;
    }

    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/employee/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => console.error(err));
    } else {
      await axios
        .post('/api/employee', moduleData)
        .then((res) => {
          setIsModal(false);
          setModuleId(null);
          window.alert(
            'Employee Successfully created! default password is welcome123'
          );
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/employee/getData/${'RSM'}/${moduleData?.zone}`)
      .then((res) => setEmpData(res.data))
      .catch((err) => console.error(err));
  }, [moduleData.zone]);

  return (
    <>
      <GridInputs
        id={'name'}
        name={'name'}
        html_for={'name'}
        label_name={'Name*'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'username'}
        name={'username'}
        html_for={'username'}
        label_name={'User Email*'}
        placeholder={'user@mushin.com'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.username}
        onChange={handleModulData}
      />

      <GridInputs
        id={'contact'}
        name={'contact'}
        html_for={'contact'}
        label_name={'Contact'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.contact}
        onChange={handleModulData}
      />

      <SelectGridMui
        name="designation"
        value={moduleData?.designation || ''}
        lable={'Designation*'}
        onChange={(e: any) => handleModulData(e)}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="RSM">RSM</MenuItem>
        <MenuItem value="SE">SE</MenuItem>
      </SelectGridMui>

      <SelectGridMui
        name="zone"
        value={moduleData?.zone || ''}
        lable={'Zone*'}
        onChange={(e: any) => {
          moduleData[e.target.name] = e.target.value;
          moduleData.under_supervision = null;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="NORTH">NORTH</MenuItem>
        <MenuItem value="EAST">EAST</MenuItem>
        <MenuItem value="WEST">WEST</MenuItem>
        <MenuItem value="SOUTH">SOUTH</MenuItem>
      </SelectGridMui>

      <GridInputs
        id={'emp_id'}
        name={'emp_id'}
        html_for={'emp_id'}
        label_name={'Employee ID'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.emp_id}
        onChange={handleModulData}
      />

      {moduleData.designation === 'SE' && moduleData.zone && (
        <AutocompleteGridmui
          label={'Under Supervision*'}
          key={moduleData.zone}
          id={'name'}
          option_name={'name'}
          value={moduleData?.under_supervision || ''}
          arrayofObj={empData}
          onChange={(e, value) => {
            moduleData.under_supervision = value;
            setRender((prev) => !prev);
          }}
        />
      )}

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={`${alertTextRef.current} feilds are mandotory!`}
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default Create;
