import TableHeaderHtmlCustom from '../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../components/ui';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#66d9e8',
  // color: '#fff',
  minWidth: '100px',
};
const style2 = {
  backgroundColor: '#ffd8a8',
  // color: '#fff',
  // minWidth: '80px',
};
const style3 = {
  backgroundColor: '#faa2c1',
  // color: '#fff',
  // minWidth: '80px',
};

const tableHeaderObj = [
  [
    {
      text: `Customer Feedback Average-Total Target 5 No's each month`,
      style: style1,
      colspan: 5,
    },
    {
      text: 'Point wise analysis',
      style: { ...style1, backgroundColor: '#eebefa' },
      colspan: 6,
    },
  ],
  [
    { text: 'Zone', style: style2 },
    { text: 'Month', style: style2 },
    { text: 'Target No of Customer', style: style2 },
    { text: 'Achieved No. Of Customers', style: style2 },
    {
      text: 'Achieved %',
      style: style2,
    },
    {
      text: 'Quality-25',
      style: style3,
    },
    {
      text: 'Cost-5',
      style: style3,
    },
    {
      text: 'Delivery-35',
      style: style3,
    },
    {
      text: 'Technical/Commercial Assistance-10',
      style: style3,
    },
    {
      text: 'Management-25',
      style: style3,
    },
    {
      text: 'Avg. Score of Total Customers',
      style: style3,
    },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Customer Feedback ${year}-${year && year + 1}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
