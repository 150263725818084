import { MenuItem, Paper, Stack, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateButton } from '../../components/common/button/CustomButtons';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { PersitedDatePicker } from '../../components/common';
import { Loader } from '../../components/common/Loader';
import CustomSelect from '../../components/common/SelectField';
import { BsUnlockFill, BsLockFill } from 'react-icons/bs';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import HeaderCreate from './HeaderCreate';
interface MyData {
  moduleData: any;
  isLoading: boolean;
  DialogSync: any;
  filterData: any;
  empData: any;
  setFilterData: any;
  confirmSync: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
}
const Create = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  // const isView = useQuery('isView') === 'true' ? true : false;
  const [islock, setIsLock] = useState<boolean>(true);
  const [isView, setisView] = useState<boolean>(false);
  const {
    moduleData,
    isLoading,
    DialogSync,
    confirmSync,
    error,
    filterData,
    setFilterData,
    empData,
    setRender,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    if (!date) {
      alert('date required');
      return;
    }

    if (moduleData._id) {
      const ans = await confirmSync();
      if (!ans) return;
      axios
        .put(`/api/preminumFreightRoute/${moduleData._id}`, moduleData)
        .then((res) => {
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    } else {
      const ans = await confirmSync();
      if (!ans) return;
      const userid = filterData ? filterData?._id : state.user?._id;
      if (!userid) return;
      moduleData.employee_id = userid;
      moduleData.created_date = date;
      moduleData.year = new Date(date).getFullYear();
      axios
        .post(`/api/preminumFreightRoute`, moduleData)
        .then((res) => {
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          empData,
          filterData,
          setFilterData,
          islock,
          setIsLock,
          setisView,
          setRender,
          handleUpdate,
        }}
      />
      <TController
        {...{
          moduleData,
          isView,
        }}
      />

      <DialogSync isSubmitContent={false} />
    </Box>
  );
};

export default Create;
