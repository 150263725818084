import {
  Box,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useAuth } from '../../../services/auth/AuthProvider';
import CustomSelect from '../../../components/common/SelectField';
import { BsUnlockFill, BsLockFill } from 'react-icons/bs';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../../components/common';
import { useQuery } from '../../../hooks/UseQuery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderCreate = ({
  empData,
  filterData,
  setFilterData,
  islock,
  setIsLock,
  setisView,
  setRender,
  handleUpdate,
  moduleData,
  setModuleData,
}: any) => {
  const { state } = useAuth();
  const date = useQuery('date');
  const toggleValue = useQuery('toggleValue');
  const [alignment, setAlignment] = useState(toggleValue || 'add');
  const navigate = useNavigate();
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <PersitedDatePicker label="Search By Year" views={['year']} />
          {(state.user?.userType === state.IS.RSM ||
            state.user?.userType === state.IS.HOD) && (
            <>
              <CustomSelect
                name="remarks"
                label="Zone"
                value={state?.user?.zone}
                disabled={state.user?.userType === state.IS.HOD ? false : true}
                sx={{
                  textAlign: 'center',
                  width: '200px',
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value="NORTH">NORTH</MenuItem>
                <MenuItem value="EAST">EAST</MenuItem>
                <MenuItem value="WEST">WEST</MenuItem>
                <MenuItem value="SOUTH">SOUTH</MenuItem>
              </CustomSelect>

              <AutocompleteMuiCustom
                sx={{
                  width: '200px',
                }}
                label={state.user?.userType === state.IS.HOD ? 'RSM' : 'SE'}
                id={'name'}
                option_name={'name'}
                value={filterData || ''}
                arrayofObj={empData}
                onChange={(e, value: any) => {
                  setFilterData(value);
                  setIsLock(true);
                  value ? setisView(true) : setisView(false);
                  setRender((prev: any) => !prev);
                }}
              />
            </>
          )}
          {filterData &&
            (state.user?.userType === state.IS.RSM ||
              state.user?.userType === state.IS.HOD) && (
              <>
                {islock ? (
                  <BsLockFill
                    style={{
                      cursor: 'pointer',
                      color: '#e03131',
                    }}
                    size="3rem"
                    onDoubleClick={(e) => {
                      setIsLock(false);
                      setisView(false);
                    }}
                    onClick={(e) => e.preventDefault()}
                    title="Click to Unlock"
                  />
                ) : (
                  <BsUnlockFill
                    style={{
                      cursor: 'pointer',
                      color: '#099268',
                    }}
                    size="3rem"
                    onDoubleClick={(e) => {
                      setIsLock(true);
                      setisView(true);
                    }}
                    onClick={(e) => e.preventDefault()}
                    title="Click to Lock"
                  />
                )}
              </>
            )}
        </Stack>

        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={(event: any, newAlignment: string) => {
            setAlignment(newAlignment);
            if (newAlignment === 'dashboard') {
              navigate(`/se/ComplaintsDashboard?toggleValue=${newAlignment}`);
            }
            if (newAlignment === 'add') {
              navigate(`/se/addComplaints?toggleValue=${newAlignment}`);
            }
          }}
          aria-label="Platform"
        >
          <ToggleButton value="add">
            Customer Complaints <span style={{ opacity: '0' }}>Dashboard</span>
          </ToggleButton>
          <ToggleButton value="dashboard">
            Customer Complaints Dashboard
          </ToggleButton>
        </ToggleButtonGroup>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
              visibility: 'hidden',
              PointerEvent: 'none',
            }}
          >
            ADD ROWS
          </CustomButton>
          {(state.user?.userType === state.IS.RSM ||
            state.user?.userType === state.IS.SE) && (
            <UpdateButton
              label="update"
              sx={{ mr: '37px', fontSize: '2rem' }}
              size="large"
              onClick={() => {
                handleUpdate();
              }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderCreate;
