export const ToggleButtonMap = [
  {
    value: 'add',
    text_1: 'Commitment Failure',
    text_2: 'Dashboard',
  },
  {
    value: 'dashboard',
    text_1: 'Commitment Failure Dashboard',
  },
];

export const navigationMap: any = {
  dashboard: '/se/commitmentFailureDashboard',
  add: '/se/addCommitmentFailure',
};

export const apiConstantAddParts = {
  delete: `/api/addCommitmentFailureRoute`,
  update: `/api/addCommitmentFailureRoute`,
  get: `/api/addCommitmentFailureRoute`,
  post: `/api/addCommitmentFailureRoute`,
};

export const apiConstantDashboard = {
  delete: `/api/commitmentFailureDashboardRoute`,
  update: `/api/commitmentFailureDashboardRoute`,
  get: `/api/commitmentFailureDashboardRoute`,
  post: `/api/commitmentFailureDashboardRoute`,
};
