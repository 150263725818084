import { useState } from 'react';
import { TableInput } from '../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useAuth } from '../../../services/auth/AuthProvider';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  year: any;
  isView: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
  isView,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    moduleData[item][e.target.name] = e.target.value;
  };
  //  background: '#FFF2CC'
  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{state.user?.zone}</td>
        <td style={{ minWidth: '170px', textAlign: 'center' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="total_so"
            value={moduleData?.[item]?.total_so}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="order_cancelled"
            value={moduleData?.[item]?.order_cancelled}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="value_of_order_cancelled"
            value={moduleData?.[item]?.value_of_order_cancelled}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="production"
            value={moduleData?.[item]?.production}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="purchase"
            value={moduleData?.[item]?.purchase}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="sales"
            value={moduleData?.[item]?.sales}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="customer"
            value={moduleData?.[item]?.customer}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="qa"
            value={moduleData?.[item]?.qa}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="others"
            value={moduleData?.[item]?.others}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
