import { ReactNode } from 'react';
import { Table } from './styled';
import TableScroll from './TableScroll';
interface IProps {
  children?: ReactNode;
  style?: any;
}
const RenderTable = ({ children, style, ...rest }: IProps) => {
  return (
    <TableScroll {...rest}>
      <Table style={{ ...style }} cellSpacing={0}>
        {children}
      </Table>
    </TableScroll>
  );
};

export default RenderTable;
