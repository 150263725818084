import { allMonths } from '../../../utils/helpers/allMonths';

export class ModuleDataHandler {
  moduleData: any;

  constructor(moduleData: any) {
    this.moduleData = moduleData;
  }

  calculateTOTALNOENQ(month: string) {
    const { carry_forward_enquiries = 0, new_for_current_month = 0 } =
      this.getListData(month);

    this.updateListProperty(
      month,
      'total_no_enq_total_open_new_recd',
      Number(carry_forward_enquiries) + Number(new_for_current_month)
    );

    this.calculateTotal(
      'carry_forward_enquiries',
      'total_carry_forward_enquiries'
    );
    this.calculateTotal('new_for_current_month', 'total_new_for_current_month');

    this.calculateTotal(
      'total_no_enq_total_open_new_recd',
      'total_total_no_enq_total_open_new_recd'
    );
  }

  calOpencarryforwardcurrentmonth(month: string) {
    const {
      converted_from_carry_forward_enquiries = 0,
      converted_from_current_month_enquiries = 0,
      lost_from_carry_forward_enquiries = 0,
      lost_from_current_month_enquiries = 0,
    } = this.getListData(month);

    this.updateListProperty(
      month,
      'open_carry_forward_current_month',
      Number(converted_from_carry_forward_enquiries) -
        Number(converted_from_current_month_enquiries) -
        Number(lost_from_carry_forward_enquiries) -
        Number(lost_from_current_month_enquiries)
    );

    this.calculateTotal(
      'converted_from_carry_forward_enquiries',
      'total_converted_from_carry_forward_enquiries'
    );
    this.calculateTotal(
      'converted_from_current_month_enquiries',
      'total_converted_from_current_month_enquiries'
    );
    this.calculateTotal(
      'lost_from_carry_forward_enquiries',
      'total_lost_from_carry_forward_enquiries'
    );
    this.calculateTotal(
      'lost_from_current_month_enquiries',
      'total_lost_from_current_month_enquiries'
    );
    this.calculateTotal(
      'open_carry_forward_current_month',
      'total_open_carry_forward_current_month'
    );
  }

  calculateYtdActual(allMonths: string[], getFullYear: string) {
    let upTotal = 0;
    const yearlyTarget = Number(this.moduleData?.user_annual_target) || 0;
    let calYearlyTarget = yearlyTarget;
    allMonths.forEach((month, mIdx) => {
      const { mtd_actual = 0 } = this.getListData(month);

      //mtd_actual actual because only want to chanage calYearlyTarget when mtd_actual present
      upTotal += mtd_actual;
      if (mIdx === 0) {
        this.updateListProperty(month, 'ytd_target', Number(calYearlyTarget));
      } else {
        const mtd_actual_prev =
          this.moduleData[allMonths[mIdx - 1]].mtd_actual || 0;
        calYearlyTarget -= mtd_actual_prev;
        this.updateListProperty(month, 'ytd_target', Number(calYearlyTarget));
      }

      this.updateListProperty(month, 'ytd_actual', Number(upTotal));
      this.updateListProperty(
        month,
        'ach_per',
        upTotal && Number(((upTotal / yearlyTarget) * 100).toFixed(2))
      );
    });
  }

  // all totals function
  calculateTotal(key_name: any, update_key_name: any) {
    const total = allMonths.reduce((accumulator: any, month: any) => {
      if (
        this.moduleData.hasOwnProperty(month) &&
        this.moduleData[month].hasOwnProperty(key_name)
      ) {
        return (+accumulator || 0) + +this.moduleData[month][key_name];
      }
      return accumulator;
    }, 0);
    this.moduleData[update_key_name] = total;
  }

  caclTotalArray(month: string, key_name: string, update_key_name: string) {
    const total = this.moduleData[month]?.[key_name]?.reduce(
      (accumalator: any, currentValue: any) => {
        if (currentValue) {
          return (+accumalator || 0) + (+currentValue || 0);
        }
        return accumalator;
      }
    );
    this.updateListProperty(month, update_key_name, total);
  }

  private getListData(month: string) {
    if (!this.moduleData[month]) {
      this.moduleData[month] = {};
    }
    return this.moduleData[month];
  }

  private updateListProperty(month: string, property: string, value: any) {
    this.moduleData[month][property] = value;
  }
}
