import { useState } from 'react';
import { TableInput } from '../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useQuery } from '../../../hooks/UseQuery';
import { allMonths } from '../../../utils/helpers/allMonths';
import { useAuth } from '../../../services/auth/AuthProvider';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);
  const { state } = useAuth();

  const handleChange = (e: any) => {
    moduleData[item][e.target.name] = Number(e.target.value);
  };
  //  background: '#FFF2CC'
  return (
    <>
      <tr>
        <td style={{ textAlign: 'start' }}>{state.user?.zone}</td>
        <td style={{ minWidth: '170px', textAlign: 'start' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="carry_forward_enquiries"
            value={moduleData?.[item]?.carry_forward_enquiries || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTOTALNOENQ(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="new_for_current_month"
            value={moduleData?.[item]?.new_for_current_month || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTOTALNOENQ(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', background: '#FFF2CC' }}>
          {moduleData?.[item]?.total_no_enq_total_open_new_recd}
        </td>
        {/*  */}
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="converted_from_carry_forward_enquiries"
            value={
              moduleData?.[item]?.converted_from_carry_forward_enquiries || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calOpencarryforwardcurrentmonth(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="converted_from_current_month_enquiries"
            value={
              moduleData?.[item]?.converted_from_current_month_enquiries || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calOpencarryforwardcurrentmonth(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="lost_from_carry_forward_enquiries"
            value={
              moduleData?.[item]?.lost_from_carry_forward_enquiries || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calOpencarryforwardcurrentmonth(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="lost_from_current_month_enquiries"
            value={
              moduleData?.[item]?.lost_from_current_month_enquiries || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calOpencarryforwardcurrentmonth(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', background: '#FFF2CC' }}>
          {moduleData?.[item]?.open_carry_forward_current_month}
        </td>
        {/*  */}

        {/*  */}
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="pending_production"
            value={moduleData?.[item]?.pending_production || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'pending_production',
                'total_pending_production'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="pending_purchase"
            value={moduleData?.[item]?.pending_purchase || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'pending_purchase',
                'total_pending_purchase'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="pending_npd"
            value={moduleData?.[item]?.pending_npd || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('pending_npd', 'total_pending_npd');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="pending_from_customer"
            value={moduleData?.[item]?.pending_from_customer || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'pending_from_customer',
                'total_pending_from_customer'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="due_to_higher_rates"
            value={moduleData?.[item]?.due_to_higher_rates || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'due_to_higher_rates',
                'total_due_to_higher_rates'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="customer_not_responding"
            value={moduleData?.[item]?.customer_not_responding || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'customer_not_responding',
                'total_customer_not_responding'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="item_not_in_scope"
            value={moduleData?.[item]?.item_not_in_scope || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'item_not_in_scope',
                'total_item_not_in_scope'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="current_month_target_no"
            value={moduleData?.[item]?.current_month_target_no || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'current_month_target_no',
                'total_current_month_target_no'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="current_month_achieved_nos"
            value={moduleData?.[item]?.current_month_achieved_nos || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'current_month_achieved_nos',
                'total_current_month_achieved_nos'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="current_month_target_value"
            value={moduleData?.[item]?.current_month_target_value || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'current_month_target_value',
                'total_current_month_target_value'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="current_month_achieved_value"
            value={moduleData?.[item]?.current_month_achieved_value || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'current_month_achieved_value',
                'total_current_month_achieved_value'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cumulative_customer_target_till_now"
            value={
              moduleData?.[item]?.cumulative_customer_target_till_now || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'cumulative_customer_target_till_now',
                'total_cumulative_customer_target_till_now'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cumulative_achieved_till_now"
            value={moduleData?.[item]?.cumulative_achieved_till_now || null}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'cumulative_achieved_till_now',
                'total_cumulative_achieved_till_now'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cumulative_expected_value_for_month"
            value={
              moduleData?.[item]?.cumulative_expected_value_for_month || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'cumulative_expected_value_for_month',
                'total_cumulative_expected_value_for_month'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cumulative_achieved_value_for_month"
            value={
              moduleData?.[item]?.cumulative_achieved_value_for_month || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'cumulative_achieved_value_for_month',
                'total_cumulative_achieved_value_for_month'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cumulative_expected_value_till_now"
            value={
              moduleData?.[item]?.cumulative_expected_value_till_now || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'cumulative_expected_value_till_now',
                'total_cumulative_expected_value_till_now'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cumulative_achieved_value_till_now"
            value={
              moduleData?.[item]?.cumulative_achieved_value_till_now || null
            }
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'cumulative_achieved_value_till_now',
                'total_cumulative_achieved_value_till_now'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        {/*  */}
      </tr>
    </>
  );
};

export default TBodyRow;
