import React, { useEffect, useState } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import { SearchBar } from '../../components/common/SearchBar';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  CustomIconButton,
  EditIconButton,
} from '../../components/common/button/CustomIconButton';

import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { MdUpdate } from 'react-icons/md';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import ListInputRow from './ListInputRow';
import { useAuth } from '../../services/auth/AuthProvider';
import { Loader } from '../../components/common/Loader';
import { apiConstant, listConstant } from './constant';

const style1 = {
  background: '#fff9db',
  border: '1px solid #fff3bf',
};
const style2 = {
  background: '#e7f5ff',
  border: '1px solid #d0ebff',
};
const monthYearobj = [
  { text: 'Apr', style: style2 },
  { text: 'May', style: style2 },
  { text: 'June', style: style2 },
  { text: 'July', style: style2 },
  {
    text: 'August',
    style: style2,
  },
  {
    text: 'September',
    style: style2,
  },
  {
    text: 'October',
    style: style2,
  },
  {
    text: 'November',
    style: style2,
  },
  {
    text: 'December',
    style: style2,
  },
  {
    text: 'January',
    style: style2,
  },
  {
    text: 'February',
    style: style2,
  },
  { text: 'March', style: style2 },
];

const tableHeaderObj = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Zone', style: style1 },
    { text: 'Name', style: style1 },
    { text: 'DESIGNATION', style: style1 },
    ...monthYearobj,
    {
      text: 'RSM Annual Target',
      style: style1,
    },
    {
      text: 'Action',
      style: style1,
    },
  ],
];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isEdible, setIsEdible] = useState<boolean[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const date: any = useQuery('date');
  const { state } = useAuth();
  const [loader, setLoader] = useState(false);
  const [yearTarget, setYearTarget] = useState<any>(null);
  const [annualCompanyTarget, setAnnualCompanyTarget] = useState<number>(0);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Update Sales Rate',
    'Are you sure you want to Update this?'
  );

  const annualTotal = (dataArr: any[]) => {
    const total = dataArr?.reduce((accumulator: number, currentValue: any) => {
      if (currentValue && currentValue.user_annual_target) {
        return accumulator + currentValue.user_annual_target;
      }
      return accumulator;
    }, 0);

    setAnnualCompanyTarget(total);
  };

  useEffect(() => {
    if (!date) return;
    axios
      .get(`/api/employee/${state.user?._id}`)
      .then((res) => {
        setYearTarget(
          res?.data?.yearlyTarget[date && new Date(date).getFullYear()]
        );
      })
      .catch((err) => console.error(err));
  }, [date]);

  const fetchData = async () => {
    setLoader(true);
    axios
      .get(
        `${
          apiConstant.fetchUrl
        }/${'RSM'}?sortBy=${filter}&search=${search}&date=${date}`
      )
      .then((res) => {
        setIsEdible(Array(res?.data?.results.length).fill(false));
        setData(res?.data?.results);
        annualTotal(res?.data?.results);
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };

  useDebounce(fetchData, 500, [page, search, filter, date]);

  const handleEdible = (indexEdit: number) => {
    setIsEdible((prev) => prev.map((_, i) => i === indexEdit));
  };

  const handleUpdate = async (itemId: any, item: any) => {
    const ans = await confirmUpdate();
    if (!ans) return;
    await axios
      .post(
        `${apiConstant.updaterurl}/${itemId}/${date}?underSupervision=${item.under_supervision}`,
        item
      )
      .then((res) => {
        window.alert('successfully updated');
        handleEdible(data.length + 1);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <ChildHeader text={listConstant.childHeader}></ChildHeader>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          margin: '15px',
          width: '100%',
          gap: 2,
        }}
      >
        <SearchBar label="Search" sx={{ width: { xs: '100%', sm: '60%' } }} />
        <Stack direction={{ xs: 'column', sm: 'row' }}>
          <InputMuiCustom
            type="number"
            variant="outlined"
            name={'month'}
            placeholder="Company Annual Target"
            onChange={(e) => {
              setYearTarget(+e.target.value);
            }}
            sx={{ width: { xs: '100%', sm: 'auto' }, minWidth: '70px' }}
            value={yearTarget || ''}
          />
          <CustomIconButton
            sx={{ p: 1.2 }}
            tooltipTitle="Update"
            onClick={async () => {
              await axios
                .patch(
                  `${apiConstant.updatyearTargetrurl}/${state.user?._id}/${date}`,
                  { yearTarget: yearTarget }
                )
                .then((res) => {
                  window.alert('successfully updated yearly target');
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <MdUpdate color="#2f9e44" size={'2.5rem'} />
          </CustomIconButton>
        </Stack>
        <PersitedDatePicker
          label="Search By Year"
          views={['year']}
          sx={{
            width: '100%',
            maxWidth: '200px',
          }}
        />
      </Stack>

      <Loader loader={loader} />
      <TableCustom tableHeaderObj={tableHeaderObj} scrollable={true}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow
                  style={{
                    background: `${(index + 1) % 2 === 0 ? '#f8f9fa' : '#fff'}`,
                    transition: 'all .15s ease',
                    transform: `${
                      isEdible[index] ? 'scale(1.01)' : 'scale(1)'
                    }`,
                    boxShadow: `${
                      isEdible[index] ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                    }`,
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell
                    sx={{
                      minWidth: '100px',
                    }}
                    align="center"
                  >
                    {item?.zone}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '100px',
                    }}
                    align="center"
                  >
                    {item?.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '100px',
                    }}
                    align="center"
                  >
                    {item?.designation}
                  </TableCell>

                  <ListInputRow
                    {...{
                      data,
                      item,
                      index,
                      isEdible,
                      annualTotal,
                    }}
                  />

                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT Sale Rate"
                        onClick={() => {
                          handleEdible(index);
                        }}
                        styleIcon={{
                          color: `${isEdible[index] ? '#e67700' : '#343a40'}`,
                        }}
                      />
                      <CustomIconButton
                        sx={{ p: 0, ml: 1, mr: 4 }}
                        tooltipTitle="Update"
                        onClick={() => handleUpdate(item?._id, item)}
                      >
                        <MdUpdate color="#2f9e44" size={'2.5rem'} />
                      </CustomIconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          <TableRow>
            <TableCell align="center" colSpan={16}>
              Annual Company Target Assigned
            </TableCell>
            <TableCell align="center" sx={{ p: 0 }}>
              <InputMuiCustom
                sx={{ minWidth: '80px' }}
                type="number"
                variant="outlined"
                name={'month'}
                placeholder=""
                value={annualCompanyTarget}
                InputProps={{
                  readOnly: true,
                }}
              />
            </TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableBody>
      </TableCustom>
      <DialogUpdate isSubmitContent={false} />
    </>
  );
};

export default List;
