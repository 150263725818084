import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    { text: 'Complaint No.', style: { ...style1, width: '200px' } },
    { text: 'Customer Name', style: style1 },
    { text: 'Sales Employee', style: style1 },
    { text: 'Part', style: style1 },
    { text: 'Remarks', style: style1 },
    { text: 'Month end Status', style: style1 },
    { text: 'Latest Status', style: style1 },
    { text: 'Visit', style: style1 },
    { text: 'Expense', style: style1 },
    { text: 'Premium Freight', style: style1 },
    { text: 'Customer Type', style: style1, colspan: 2 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
