export const ToggleButtonMap = [
  {
    value: 'add',
    text_1: 'Production Feasibility',
    text_2: 'Dashboard',
  },
  {
    value: 'dashboard',
    text_1: 'Production Feasibility Dashboard',
  },
];

export const navigationMap: any = {
  dashboard: '/se/productionFeasibilityDashboard',
  add: '/se/addProductionFeasibility',
};

export const apiConstantAddParts = {
  delete: `/api/addProductionFeasibilityRoute`,
  update: `/api/addProductionFeasibilityRoute`,
  get: `/api/addProductionFeasibilityRoute`,
  post: `/api/addProductionFeasibilityRoute`,
};

export const apiConstantDashboard = {
  delete: `/api/productionFeasibilityDashboardRoute`,
  update: `/api/productionFeasibilityDashboardRoute`,
  get: `/api/productionFeasibilityDashboardRoute`,
  post: `/api/productionFeasibilityDashboardRoute`,
};
