import { RiTeamFill } from 'react-icons/ri';
import { PiTargetDuotone } from 'react-icons/pi';
import { FcPlanner } from 'react-icons/fc';
import { RenderTiles } from '../../components/common';

const HODTilesObj = [
  {
    sNo: 1,
    link: '/hod/masterlistEmployee',
    isLink: true,
    icon: <RiTeamFill size="5rem" color="#1864ab" />,
    isIcon: true,
    p: 'SALES',
    span: 'TEAM',
  },
  {
    sNo: 1,
    link: '/hod/hodSetTargetList',
    isLink: true,
    icon: <PiTargetDuotone size="5rem" color="#099268" />,
    isIcon: true,
    p: 'ASSIGN',
    span: 'TEAM TARGET',
  },
  {
    sNo: 1,
    link: '/hod/hodAnnulOverview',
    isLink: true,
    icon: <FcPlanner size="5rem" color="#099268" />,
    isIcon: true,
    p: 'Annual',
    span: 'RSM PLAN',
  },
];

const AdminHome = () => {
  return (
    <RenderTiles
      tilesObj={HODTilesObj}
      height="100vh"
      justify="start"
      columns="0fr"
    />
  );
};

export default AdminHome;
