import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// @params navigationMap = {
//   dashboard: '',
//   add: '',
// };

interface Iprops {
  alignment: any;
  setAlignment?: any;
  ToggleButtonMap: any[];
  navigationMap?: any;
  onChange: any;
}

const ToggleButtonGroupCustom = ({
  alignment,
  onChange,
  ToggleButtonMap,
  setAlignment,
  navigationMap,
}: Iprops) => {
  const navigate = useNavigate();

  return (
    <>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={onChange}
        aria-label="Platform"
      >
        {ToggleButtonMap?.map((item, tIdx) => (
          <ToggleButton value={item.value} key={tIdx}>
            {item?.text_1}
            <span style={{ opacity: '0' }}>{item?.text_2}</span>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default ToggleButtonGroupCustom;
