const keyPropery = {
  no_of_failure: null,
  production: null,
  purchase: null,
};

export const initialModuleState = {
  april: { ...keyPropery },
  may: { ...keyPropery },
  june: { ...keyPropery },
  july: { ...keyPropery },
  august: { ...keyPropery },
  september: { ...keyPropery },
  october: { ...keyPropery },
  november: { ...keyPropery },
  december: { ...keyPropery },
  january: { ...keyPropery },
  february: { ...keyPropery },
  march: { ...keyPropery },
};
