import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../components/common/useConfirm';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';

const useGetData = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const date = useQuery('date');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [filterData, setFilterData] = useState<any>('');
  const { state } = useAuth();
  const [DialogSync, confirmSync] = useConfirm(
    'Sync New Parts',
    'Are you sure you want to Sync ? Make sure to save any changes before syncing!'
  );

  useEffect(() => {
    axios
      .get(
        `/api/employee/getData/${'SE'}/${state.user?.zone}?rsmId=${
          state.user?._id
        }`
      )
      .then((res) => setEmpData(res.data))
      .catch((err) => console.error(err));
  }, [state.user?.zone]);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      console.error(filterData);
      if (filterData) {
        try {
          const response = await axios.get(
            `/api/SEroute/${date}/${filterData?._id}`
          );
          setModuleData(response.data);
          setIsLoading(false);
        } catch (err: any) {
          setIsLoading(false);
          setModuleData({});
          setError(err);
        }
      } else {
        axios
          .get(`/api/HODandRSMroute/${date}/${state.user?._id}`)
          .then((response) => {
            setModuleData(response.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setError(err);
          });
      }
    };

    handleApi();
    setIsLoading(false);
  }, [render, date]);

  return {
    moduleData,
    isLoading,
    DialogSync,
    filterData,
    setFilterData,
    empData,
    error,
    setRender,
  };
};

export default useGetData;
