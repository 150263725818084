import { TableCell } from '@mui/material';
import React, { useState } from 'react';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import { ModuleDataHandler } from './handleReportCalc ';
import { allMonths } from '../../utils/helpers/allMonths';

const ListInputRow = ({ data, item, index, isEdible, annualTotal }: any) => {
  const [render, setRender] = useState(false);
  const dataHandler = new ModuleDataHandler(item);

  return (
    <>
      {allMonths.map((month: any, mIdx) => (
        <TableCell align="center" key={`${month}_month`} sx={{ p: 0 }}>
          <InputMuiCustom
            type="number"
            variant="outlined"
            name={month}
            placeholder=""
            onChange={(e) => {
              if (typeof item[month] !== 'object') {
                item[month] = {};
              }
              item[month].target = +e.target.value;
              dataHandler.calculateTotal('target', 'user_annual_target');
              annualTotal(data);
              // setRender((prev) => !prev);
            }}
            sx={{ minWidth: '70px', fontSize: '1rem' }}
            value={item[month]?.target || null}
            InputProps={{
              readOnly: !isEdible[index],
            }}
          />
        </TableCell>
      ))}
      <TableCell align="center" sx={{ p: 0 }}>
        <InputMuiCustom
          sx={{ minWidth: '80px' }}
          type="number"
          variant="outlined"
          name={'month'}
          placeholder=""
          value={item?.user_annual_target || null}
          InputProps={{
            readOnly: true,
          }}
        />
      </TableCell>
    </>
  );
};

export default ListInputRow;
