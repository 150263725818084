import SEHome from '../pages/HomeSE/SEHome';
import { SEAnnulOverview } from '../pages/annualOverviewSE';
import { NewCustomerEnquiryOrderConversion } from '../pages/newCustomerEnquiryVsOrderConversion';
import { PremiumFreight } from '../pages/premiumFreight';
import { Profile } from '../pages/profile';
import { Kaizen } from '../pages/kaizen';
import { SETrainingRecordAndEffectiveness } from '../pages/trainingRecordAndEffecivenessSE';
import { BizLostDueToPoorQlty } from '../pages/bizLostDueToPoorQlty';
import { CustomerFeedback } from '../pages/customerFeedback';
import { OrderCancellation } from '../pages/orderCancellation';
import ThingsWentRightAndWrong from '../pages/thingsWentRightAndWrong/tiles';
import { WrongThings } from '../pages/thingsWentRightAndWrong/WrongThings/index';
import { RightThings } from '../pages/thingsWentRightAndWrong/RightThings/index';
import { MonthlyDeliveryFailure } from '../pages/monthlyDeliveryFailure';
import {
  AddComplaints,
  ComplaintsDashboard,
} from '../pages/customerComplaints';
import {
  AddMaterialReturnValue,
  MaterialReturnValueDashboard,
} from '../pages/MaterialReturnValue';
import {
  AddCommitmentFailure,
  CommitmentFailureDashboard,
} from '../pages/commitmentFailure';
import {
  AddProductionFeasibility,
  ProductionFeasibilityDashboard,
} from '../pages/productionFeasibility';
import {
  AddPurchaseFeasibility,
  PurchaseFeasibilityDashboard,
} from '../pages/purchaseFeasibility';
import {
  ActivePassiveStatusDashboard,
  ActivePassiveStatusDashboard_1,
  AddActivePassiveStatus,
} from '../pages/activePassiveStatus';

export const IndexSE = [
  {
    path: 'home',
    element: <SEHome />,
  },
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'seAnnulOverview',
    element: <SEAnnulOverview />,
  },
  {
    path: 'newCustomerEnquiryOrderConversion',
    element: <NewCustomerEnquiryOrderConversion />,
  },
  {
    path: 'premiumFreight',
    element: <PremiumFreight />,
  },
  {
    path: 'seTrainingRecordAndEffectiveness',
    element: <SETrainingRecordAndEffectiveness />,
  },
  {
    path: 'kaizen',
    element: <Kaizen />,
  },
  {
    path: 'seBizLostDueToPoorQlty',
    element: <BizLostDueToPoorQlty />,
  },
  {
    path: 'seCustomerFeedback',
    element: <CustomerFeedback />,
  },
  {
    path: 'orderCancellation',
    element: <OrderCancellation />,
  },
  {
    path: 'thingsWentRightAndWrong',
    element: <ThingsWentRightAndWrong />,
  },
  {
    path: 'wrongThings',
    element: <WrongThings />,
  },
  {
    path: 'rightThings',
    element: <RightThings />,
  },
  {
    path: 'seMonthlyDeliveryFailure',
    element: <MonthlyDeliveryFailure />,
  },
  {
    path: 'addComplaints',
    element: <AddComplaints />,
  },
  {
    path: 'complaintsDashboard',
    element: <ComplaintsDashboard />,
  },
  {
    path: 'addMaterialReturnValue',
    element: <AddMaterialReturnValue />,
  },
  {
    path: 'materialReturnValueDashboard',
    element: <MaterialReturnValueDashboard />,
  },
  {
    path: 'addCommitmentFailure',
    element: <AddCommitmentFailure />,
  },
  {
    path: 'commitmentFailureDashboard',
    element: <CommitmentFailureDashboard />,
  },
  {
    path: 'addProductionFeasibility',
    element: <AddProductionFeasibility />,
  },
  {
    path: 'productionFeasibilityDashboard',
    element: <ProductionFeasibilityDashboard />,
  },
  {
    path: 'addPurchaseFeasibility',
    element: <AddPurchaseFeasibility />,
  },
  {
    path: 'purchaseFeasibilityDashboard',
    element: <PurchaseFeasibilityDashboard />,
  },
  {
    path: 'addActivePassiveStatus',
    element: <AddActivePassiveStatus />,
  },
  {
    path: 'activePassiveStatusDashboard',
    element: <ActivePassiveStatusDashboard />,
  },
  {
    path: 'activePassiveStatusDashboard_1',
    element: <ActivePassiveStatusDashboard_1 />,
  },
];
