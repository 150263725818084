import { ReactNode, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { CloseButton } from './button/CustomButtons';

const DialogCustom = styled(Dialog)(({ theme }) => ({
  // width: 'calc(100vw * (70 / 100))',

  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DialogTitleCustom = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: '#f1f3f5',
  fontWeight: '400',
}));

interface iprops {
  title: string;
  children: ReactNode;
  openModal: boolean;
  closeModal: () => void;
  isModalCloseBtn?: boolean;
}

const ModalCustom = (props: iprops) => {
  const { title, children, openModal, closeModal, isModalCloseBtn } = props;

  const handleClose = (event: object, reason: string) => {
    if (reason && reason == 'backdropClick') return;
    closeModal();
  };

  return (
    <div>
      <DialogCustom
        onClose={handleClose}
        open={openModal}
        disableEscapeKeyDown={true}
        maxWidth={false}
        disableScrollLock
        disableRestoreFocus
      >
        <DialogTitleCustom>{title}</DialogTitleCustom>
        <DialogContent dividers>{children}</DialogContent>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            top: '0px',
          }}
        >
          <CloseButton label="close" onClick={closeModal} />
        </div>
      </DialogCustom>
    </div>
  );
};

export default ModalCustom;
