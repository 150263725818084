export const ToggleButtonMap = [
  {
    value: 'add',
    text_1: 'Active Passive status',
    text_2: 'Dashboard',
  },
  {
    value: 'dashboard',
    text_1: 'Active Passive status Dashboard 1',
  },
  {
    value: 'dashboard_1',
    text_1: 'Active Passive status Dashboard 2',
  },
];

export const navigationMap: any = {
  dashboard: '/se/activePassiveStatusDashboard',
  dashboard_1: '/se/activePassiveStatusDashboard_1',
  add: '/se/addActivePassiveStatus',
};

export const apiConstantAddParts = {
  delete: `/api/addActivePassivestatusRoute`,
  update: `/api/addActivePassivestatusRoute`,
  get: `/api/addActivePassivestatusRoute`,
  post: `/api/addActivePassivestatusRoute`,
};

export const apiConstantDashboard = {
  delete: `/api/activePassivestatusDashboardRoute`,
  update: `/api/activePassivestatusDashboardRoute`,
  get: `/api/activePassivestatusDashboardRoute`,
  post: `/api/activePassivestatusDashboardRoute`,
};
