import { useState } from 'react';
import TBodyRow from './TBodyRow';
import { useQuery } from '../../../hooks/UseQuery';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { allMonths } from '../../../utils/helpers/allMonths';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear().toString().slice(-2);
  const getFullYear = date && new Date(date).getFullYear().toString();
  const dataHandler = new ModuleDataHandler(moduleData);
  dataHandler.calculateTotal('target', 'total_target');

  return (
    <tbody>
      {allMonths.map((item: any, index: number) => (
        <TBodyRow
          {...{ moduleData, item, index, setRender, year, getFullYear }}
        />
      ))}

      <tr>
        <th colSpan={2}>{'TOTAL'}</th>
        <th>{moduleData.total_carry_forward_enquiries}</th>
        <th>{moduleData.total_new_for_current_month}</th>
        <th>{moduleData.total_total_no_enq_total_open_new_recd}</th>
        <th>{moduleData.total_converted_from_carry_forward_enquiries}</th>
        <th>{moduleData.total_converted_from_current_month_enquiries}</th>
        <th>{moduleData.total_lost_from_carry_forward_enquiries}</th>
        <th>{moduleData.total_lost_from_current_month_enquiries}</th>
        <th>{moduleData.total_open_carry_forward_current_month}</th>
        <th>{moduleData.total_pending_production}</th>
        <th>{moduleData.total_pending_purchase}</th>
        <th>{moduleData.total_pending_npd}</th>
        <th>{moduleData.total_pending_from_customer}</th>
        <th>{moduleData.total_due_to_higher_rates}</th>
        <th>{moduleData.total_customer_not_responding}</th>
        <th>{moduleData.total_item_not_in_scope}</th>
        <th>{moduleData.total_current_month_target_no}</th>
        <th>{moduleData.total_current_month_achieved_nos}</th>
        <th>{moduleData.total_current_month_target_value}</th>
        <th>{moduleData.total_current_month_achieved_value}</th>
        <th>{moduleData.total_cumulative_customer_target_till_now}</th>
        <th>{moduleData.total_cumulative_achieved_till_now}</th>
        <th>{moduleData.total_cumulative_expected_value_for_month}</th>
        <th>{moduleData.total_cumulative_achieved_value_for_month}</th>
        <th>{moduleData.total_cumulative_expected_value_till_now}</th>
        <th>{moduleData.total_cumulative_achieved_value_till_now}</th>
      </tr>
    </tbody>
  );
};

export default TBody;
