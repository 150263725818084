import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useQuery } from '../../../../hooks/UseQuery';
import { allMonths } from '../../../../utils/helpers/allMonths';
import { useAuth } from '../../../../services/auth/AuthProvider';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  isView: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
  isView,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    moduleData[item][e.target.name] = e.target.value;
  };
  //  background: '#FFF2CC'
  return (
    <>
      <tr>
        <td style={{ textAlign: 'start' }}>{state.user?.zone}</td>
        <td style={{ minWidth: '170px', textAlign: 'start' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="total_numbers_credit_note"
            value={moduleData?.[item]?.total_numbers_credit_note}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="value"
            value={moduleData?.[item]?.value}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="quality"
            value={moduleData?.[item]?.quality}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="customer"
            value={moduleData?.[item]?.customer}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="sales"
            value={moduleData?.[item]?.sales}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="production"
            value={moduleData?.[item]?.production}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={true}
            type="number"
            name="others"
            value={moduleData?.[item]?.others}
          />
        </td>

        {/*  */}
      </tr>
    </>
  );
};

export default TBodyRow;
