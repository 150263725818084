const keyPropery = {
  sales_order_total: null,
  dispatch_on_time: null,
  delayed: null,
  delivery_percentage: null,
  sales: null,
  store: null,
  production: null,
  qlty: null,
  purchase: null,
  other: null,
};

export const initialModuleState = {
  april: { ...keyPropery },
  may: { ...keyPropery },
  june: { ...keyPropery },
  july: { ...keyPropery },
  august: { ...keyPropery },
  september: { ...keyPropery },
  october: { ...keyPropery },
  november: { ...keyPropery },
  december: { ...keyPropery },
  january: { ...keyPropery },
  february: { ...keyPropery },
  march: { ...keyPropery },

  TOTAL_sales_order_total: null,
  TOTAL_dispatch_on_time: null,
  TOTAL_delayed: null,
  TOTAL_delivery_percentage: null,
  TOTAL_sales: null,
  TOTAL_store: null,
  TOTAL_production: null,
  TOTAL_qlty: null,
  TOTAL_purchase: null,
  TOTAL_other: null,
};
