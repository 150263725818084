import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { fontGrid } from '@mui/material/styles/cssUtils';
import { Block } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface PropTypes {
  children: any;
  color?: any;
  onClick?: () => void;
  size?: 'small' | 'small' | 'medium';
  disabled?: boolean;
  // variant?: "text" | "outlined" | "contained";
  sx?: {};
  tooltipTitle: string;
}

export function CustomIconButton(props: PropTypes) {
  const {
    children,
    color,
    disabled,
    onClick,
    size,
    sx,
    tooltipTitle,
    ...rest
  } = props;
  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        disabled={disabled ? true : false}
        color={color ? color : 'primary'}
        size={size ? size : 'small'}
        sx={sx}
        onClick={onClick}
        {...rest}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}

export const EditIconButton = ({
  onClick,
  tooltipTitle,
  styleIcon,
}: {
  onClick?: () => void;
  tooltipTitle: string;
  styleIcon?: object;
}) => {
  return (
    <CustomIconButton
      tooltipTitle={tooltipTitle}
      color="secondary"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
      onClick={onClick}
    >
      <EditIcon sx={{ m: 0, ...styleIcon }} />
    </CustomIconButton>
  );
};

export const ViewIconButton = ({
  onClick,
  tooltipTitle,
}: {
  onClick?: () => void;
  tooltipTitle: string;
}) => {
  return (
    <CustomIconButton
      tooltipTitle={tooltipTitle}
      color="blueButton"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
      onClick={onClick}
    >
      <VisibilityIcon sx={{ color: '#265988' }} />
    </CustomIconButton>
  );
};

export const DeleteIconButton = ({
  onClick,
  tooltipTitle,
}: {
  onClick?: () => void;
  tooltipTitle: string;
}) => {
  return (
    <CustomIconButton
      tooltipTitle={tooltipTitle}
      color="blueButton"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
      onClick={onClick}
    >
      <DeleteIcon sx={{ color: '#c92a2a' }} />
    </CustomIconButton>
  );
};
export const BlockIconButton = () => {
  return (
    <CustomIconButton
      tooltipTitle="Block"
      color="blueButton"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
    >
      <Block sx={{ color: '#c92a2a' }} />
    </CustomIconButton>
  );
};

export const AddIconButton = ({
  onClick,
  tooltipTitle,
}: {
  onClick?: () => void;
  tooltipTitle: string;
}) => {
  return (
    <CustomIconButton
      tooltipTitle={tooltipTitle}
      color="blueButton"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
      onClick={onClick}
    >
      <AddIcon sx={{ color: '#265988' }} />
    </CustomIconButton>
  );
};

export const RemoveIconButton = ({
  onClick,
  tooltipTitle,
}: {
  onClick?: () => void;
  tooltipTitle: string;
}) => {
  return (
    <CustomIconButton
      tooltipTitle={tooltipTitle}
      color="blueButton"
      sx={{ m: 1 }}
      disabled={false}
      size="small"
      onClick={onClick}
    >
      <RemoveIcon sx={{ color: '#c92a2a' }} />
    </CustomIconButton>
  );
};
