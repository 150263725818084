import { ReactNode } from 'react';
import Table from '@mui/material/Table';
import { styled as muiStyled } from '@mui/system';

import TableHeaderCustom from './TableHeaderCustom';

const ScrollableTableWrapper = muiStyled<any>('div')(
  {
    width: '100%',
    marginTop: '1.5rem',
    margin: '0 2px',
    overflowY: 'auto', // Enable vertical scrolling
    '&::-webkit-scrollbar': {
      width: '8px', // Width of the scroll bar
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c0c0c0', // Color of the thumb
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a0', // Color of the thumb on hover
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0', // Color of the track
    },
    '.MuiTableCell-root': {
      padding: '5px 8px',
      fontSize: '1.4rem',
      borderCollapse: 'collapse',
    },
  },
  (props) => ({
    overflowX: props?.scrollable ? 'auto' : 'hidden', // Apply overflowX conditionally
  })
);

export const TableStyled = muiStyled(Table)({
  width: '100%',
});

type TableHeaderObj = {
  text: string;
  rowspan?: number;
  colspan?: number;
  style?: {};
};

type TableCustomProps = {
  tableHeaderObj: TableHeaderObj[][];
  children?: ReactNode;
  scrollable?: boolean; // Pass scrollable prop
};

const TableCustom = ({
  tableHeaderObj,
  children,
  scrollable = false, // Default to true if not provided
}: TableCustomProps) => {
  return (
    <ScrollableTableWrapper scrollable={scrollable}>
      <TableStyled size="small">
        <TableHeaderCustom tableHeaderObj={tableHeaderObj} />
        {children}
      </TableStyled>
    </ScrollableTableWrapper>
  );
};

export default TableCustom;
