import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../components/common/useConfirm';
import { useQuery } from '../../../hooks/UseQuery';

const useGetData = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const date = useQuery('date');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [filterData, setFilterData] = useState<any>({});
  const [DialogSync, confirmSync] = useConfirm(
    'Sync New Parts',
    'Are you sure you want to Sync ? Make sure to save any changes before syncing!'
  );

  useEffect(() => {
    axios
      .get(`/api/employee/getData/${'RSM'}/${filterData?.zone}`)
      .then((res) => setEmpData(res.data))
      .catch((err) => console.error(err));
  }, [filterData.zone]);

  useEffect(() => {
    if (!date) return;
    if (!filterData.zone && !filterData.under_supervision) return;
    const handleApi = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `/api/HODandRSMroute/${date}/${filterData?.under_supervision?._id}`
        );
        setModuleData(response.data);
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        setModuleData({});
        setError(err);
      }
    };

    handleApi();
    setIsLoading(false);
  }, [render, date]);

  return {
    moduleData,
    isLoading,
    DialogSync,
    filterData,
    empData,
    error,
    setRender,
  };
};

export default useGetData;
