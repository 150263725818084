import { MenuItem, Paper, Stack, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { PersitedDatePicker } from '../../components/common';
import CustomSelect from '../../components/common/SelectField';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import { Loader } from '../../components/common/Loader';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  DialogSync: any;
  filterData: any;
  empData: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
}

const Create = () => {
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;

  const {
    moduleData,
    isLoading,
    error,
    DialogSync,
    filterData,
    empData,
    setRender,
  }: MyData = useGetData();

  return (
    <Box>
      <Loader loader={isLoading} />
      <Box>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            pl: 2,
            pb: 1,
            pt: 2,
            gap: 2,
          }}
        >
          <PersitedDatePicker label="Search By Year" views={['year']} />

          <CustomSelect
            name="remarks"
            label="Zone"
            value={filterData.zone || ''}
            onChange={(e) => {
              filterData.zone = e.target.value;
              filterData.under_supervision = null;
              setRender((prev) => !prev);
            }}
            sx={{
              textAlign: 'center',
              width: '200px',
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="NORTH">NORTH</MenuItem>
            <MenuItem value="EAST">EAST</MenuItem>
            <MenuItem value="WEST">WEST</MenuItem>
            <MenuItem value="SOUTH">SOUTH</MenuItem>
          </CustomSelect>

          <AutocompleteMuiCustom
            sx={{
              width: '200px',
            }}
            label={'RSM'}
            key={filterData.zone}
            id={'name'}
            option_name={'name'}
            value={filterData?.under_supervision || ''}
            arrayofObj={empData}
            onChange={(e, value: any) => {
              filterData.under_supervision = value;
              setRender((prev) => !prev);
            }}
          />
        </Stack>
      </Box>

      <TController
        {...{
          moduleData,
          isView,
        }}
      />

      <DialogSync isSubmitContent={false} />
    </Box>
  );
};

export default Create;
