import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import BackupIcon from '@mui/icons-material/Backup';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface PropTypes {
  icon?: React.ReactElement;
  children: any;
  // color?:
  //   | "primary"
  //   | "secondary"
  //   | "success"
  //   | "error"
  //   | "info"
  //   | "warning"
  //   | "blueButton"
  //   | "greenButton";
  color?: any;
  onClick?: () => void;
  size?: 'small' | 'large' | 'medium';
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  sx?: {};
  type?: 'submit' | 'button' | 'reset';
  component?: string;
  target?: string;
  href?: string;
}

export function CustomButton(props: PropTypes) {
  const {
    children,
    color,
    variant,
    disabled,
    onClick,
    size,
    sx,
    icon,
    type,
    ...rest
  } = props;
  return (
    <Button
      disabled={disabled ? true : false}
      color={color ? color : 'primary'}
      size={size ? size : 'large'}
      variant={variant ? variant : 'contained'}
      sx={sx}
      startIcon={icon ? icon : <AddIcon />} // icons
      onClick={onClick}
      type={(type && type) || 'button'}
      {...rest}
    >
      {children}
    </Button>
  );
}

export const SaveButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="blueButton"
      sx={{ margin: '10px' }}
      icon={<SaveIcon />}
      disabled={false}
      size="large"
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const UpdateButton = ({
  onClick,
  label,
  sx,
  size,
  ...rest
}: {
  onClick?: () => void;
  label: string;
  sx?: object;
  size?: 'small' | 'large' | 'medium';
}) => {
  return (
    <CustomButton
      color="greenButton"
      sx={sx}
      icon={<UpdateIcon />}
      disabled={false}
      onClick={onClick}
      size={size ? size : 'medium'}
      {...rest}
    >
      {label}
    </CustomButton>
  );
};

export const SubmitButton = ({
  onClick,
  label,
  type,
  ...rest
}: {
  onClick?: () => void;
  label: string;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
}) => {
  return (
    <CustomButton
      color="greenButton"
      sx={{ margin: '10px' }}
      icon={<BackupIcon />}
      disabled={false}
      size="large"
      onClick={onClick}
      type={type}
      {...rest}
    >
      {label}
    </CustomButton>
  );
};

export const CloseButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="primary"
      sx={{ margin: '10px' }}
      icon={<CloseIcon />}
      disabled={false}
      size="large"
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const AddButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="greenButton"
      sx={{ margin: '10px' }}
      icon={<AddIcon />}
      disabled={false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

// create cp button
export const AddProcessButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="secondary"
      sx={{ margin: '2px' }}
      icon={<AddIcon />}
      disabled={false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const CopyProcessButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="secondary"
      sx={{ margin: '2px' }}
      icon={<ContentCopyIcon />}
      disabled={false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const AddSpecsButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="blueButton"
      // size="medium"
      sx={{ margin: '2px' }}
      icon={<AddIcon />}
      disabled={false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const ExpandButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="blueButton"
      sx={{ margin: '2px' }}
      icon={<ExpandMoreIcon />}
      disabled={false}
      onClick={onClick}
      // size="medium"
    >
      {label}
    </CustomButton>
  );
};

export const CollapseButton = ({
  onClick,
  label,
}: {
  onClick?: () => void;
  label: string;
}) => {
  return (
    <CustomButton
      color="blueButton"
      sx={{ margin: '2px' }}
      icon={<ExpandLessIcon />}
      disabled={false}
      // size="medium"
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};
// end of create cp button
