import { allMonths } from '../../../utils/helpers/allMonths';

export class ModuleDataHandler {
  moduleData: any;

  constructor(moduleData: any) {
    this.moduleData = moduleData;
  }

  calculateAchPercentage(month: string) {
    const { target = 0, mtd_actual = 0 } = this.getListData(month);
    this.updateListProperty(
      month,
      'mtd_ach_perc',
      target && Number(((+mtd_actual / +target) * 100).toFixed(2))
    );
    this.calculateTotal('mtd_actual', 'total_mtd_actual');
    this.calculateTotal('mtd_ach_perc', 'total_mtd_ach_perc');
    // this.calculateTotal('mtd_ach_perc', 'total_mtd_ach_perc');
  }

  calculateMtdTargetPending(month: string) {
    const { target = 0, mtd_actual = 0 } = this.getListData(month);
    this.updateListProperty(
      month,
      'mtd_target_pending',
      Number(+target - +mtd_actual)
    );
    this.calculateTotal('mtd_target_pending', 'total_mtd_target_pending');
  }

  calculateYtdActual(allMonths: string[], getFullYear: string) {
    let upTotal = 0;
    const yearlyTarget = Number(this.moduleData?.user_annual_target) || 0;
    let calYearlyTarget = yearlyTarget;
    allMonths.forEach((month, mIdx) => {
      const { mtd_actual = 0 } = this.getListData(month);

      //mtd_actual actual because only want to chanage calYearlyTarget when mtd_actual present
      upTotal += mtd_actual;
      if (mIdx === 0) {
        this.updateListProperty(month, 'ytd_target', Number(calYearlyTarget));
      } else {
        const mtd_actual_prev =
          this.moduleData[allMonths[mIdx - 1]].mtd_actual || 0;
        calYearlyTarget -= mtd_actual_prev;
        this.updateListProperty(month, 'ytd_target', Number(calYearlyTarget));
      }

      this.updateListProperty(month, 'ytd_actual', Number(upTotal));
      this.updateListProperty(
        month,
        'ach_per',
        upTotal && Number(((upTotal / yearlyTarget) * 100).toFixed(2))
      );
    });
  }

  // all totals function
  calculateTotal(key_name: any, update_key_name: any) {
    const total = allMonths.reduce((accumulator: any, month: any) => {
      if (
        this.moduleData.hasOwnProperty(month) &&
        this.moduleData[month].hasOwnProperty(key_name)
      ) {
        return (+accumulator || 0) + +this.moduleData[month][key_name];
      }
      return accumulator;
    }, 0);
    this.moduleData[update_key_name] = total;
  }

  caclTotalArray(month: string, key_name: string, update_key_name: string) {
    const total = this.moduleData[month]?.[key_name]?.reduce(
      (accumalator: any, currentValue: any) => {
        if (currentValue) {
          return (+accumalator || 0) + (+currentValue || 0);
        }
        return accumalator;
      }
    );
    this.updateListProperty(month, update_key_name, total);
  }

  private getListData(month: string) {
    if (!this.moduleData[month]) {
      this.moduleData[month] = {};
    }
    return this.moduleData[month];
  }

  private updateListProperty(month: string, property: string, value: any) {
    this.moduleData[month][property] = value;
  }
}
