import { useState } from 'react';
import { TableInput } from '../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useQuery } from '../../../hooks/UseQuery';
import { allMonths } from '../../../utils/helpers/allMonths';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);

  return (
    <>
      <tr>
        <td style={{ textAlign: 'start' }}>{moduleData?.employee_id?.zone}</td>
        <td style={{ minWidth: '170px', textAlign: 'start' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>
        <td style={{ textAlign: 'center' }}>{moduleData?.employee_id?.name}</td>
        <td style={{ textAlign: 'center', background: '#FFF2CC' }}>
          {moduleData?.[item]?.target}
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            value={moduleData?.[item]?.mtd_actual || null}
            onChange={(e) => {
              if (typeof moduleData[item] !== 'object') {
                moduleData[item] = {};
              }
              const min = 0;
              const max = moduleData?.[item]?.target || 0;
              moduleData[item].mtd_actual = Math.max(
                min,
                Math.min(max, Number(e.target.value))
              );

              dataHandler.calculateAchPercentage(item);
              dataHandler.calculateMtdTargetPending(item);
              dataHandler.calculateYtdActual(allMonths, getFullYear);
              setRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          {moduleData?.[item]?.mtd_ach_perc}
          {moduleData?.[item]?.mtd_ach_perc && '%'}
        </td>
        <td style={{ textAlign: 'center' }}>
          {moduleData?.[item]?.mtd_target_pending}
        </td>
        <td style={{ textAlign: 'center' }}>
          {moduleData?.[item]?.ytd_target}
        </td>
        <td style={{ textAlign: 'center' }}>
          {moduleData?.[item]?.ytd_actual}
        </td>
        <td style={{ textAlign: 'center' }}>
          {moduleData?.[item]?.ach_per}
          {moduleData?.[item]?.ach_per && '%'}
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
