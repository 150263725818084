import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../components/common/useConfirm';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import lodash from 'lodash';
import { initialModuleState } from './initial';

const useGetData = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const date = useQuery('date');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [filterData, setFilterData] = useState<any>(null);
  const { state } = useAuth();
  const [DialogSync, confirmSync] = useConfirm(
    'Update Confirmation',
    'Are you sure you want to update?'
  );

  useEffect(() => {
    axios
      .get(
        `/api/employee/getData/${'SE'}/${state.user?.zone}?rsmId=${
          state.user?._id
        }`
      )
      .then((res) => setEmpData(res.data))
      .catch((err) => console.error(err));
  }, [state.user?.zone]);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      const userid = filterData ? filterData?._id : state.user?._id;
      setIsLoading(true);
      axios
        .get(`/api/orderCancellationRoute/${date}/${userid}`)
        .then((response) => {
          setModuleData(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData(lodash.cloneDeep(initialModuleState));
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, filterData]);

  return {
    moduleData,
    isLoading,
    DialogSync,
    filterData,
    setFilterData,
    empData,
    confirmSync,
    error,
    setRender,
  };
};

export default useGetData;
