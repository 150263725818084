import { TableInput } from '../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useAuth } from '../../../services/auth/AuthProvider';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);
  const { state } = useAuth();

  const handleChange = (e: any) => {
    moduleData[item][e.target.name] = Number(e.target.value);
  };
  //  background: '#FFF2CC'
  return (
    <>
      <tr>
        <td style={{ minWidth: '100px' }}>{state.user?.zone}</td>
        <td style={{ minWidth: '150px' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="target_no_of_customer"
            value={moduleData?.[item]?.target_no_of_customer}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculatePercentage(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="achieved_no_of_customer"
            value={moduleData?.[item]?.achieved_no_of_customer}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculatePercentage(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td
          style={{
            textAlign: 'center',
            background: '#FFF2CC',
            minWidth: '120px',
          }}
        >
          {moduleData?.[item]?.achieved_percentage}
        </td>
        {/*  */}

        {/*  */}
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="qlty_25"
            value={moduleData?.[item]?.qlty_25}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('qlty_25', 'TOTAL_qlty_25');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="cost_5"
            value={moduleData?.[item]?.cost_5}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('cost_5', 'TOTAL_cost_5');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="delivery_35"
            value={moduleData?.[item]?.delivery_35}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('delivery_35', 'TOTAL_delivery_35');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="technical_commercial_assistance_10"
            value={moduleData?.[item]?.technical_commercial_assistance_10}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'technical_commercial_assistance_10',
                'TOTAL_technical_commercial_assistance_10'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="management_25"
            value={moduleData?.[item]?.management_25}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'management_25',
                'TOTAL_management_25'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="avg_score_of_total_customers"
            value={moduleData?.[item]?.avg_score_of_total_customers}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal(
                'avg_score_of_total_customers',
                'TOTAL_avg_score_of_total_customers'
              );
              setRender((prev) => !prev);
            }}
          />
        </td>

        {/*  */}
      </tr>
    </>
  );
};

export default TBodyRow;
