import { TableInput } from '../../../components/common';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
import { useAuth } from '../../../services/auth/AuthProvider';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  year,
  getFullYear,
}: IProps) => {
  const dataHandler = new ModuleDataHandler(moduleData);
  const { state } = useAuth();

  const handleChange = (e: any) => {
    moduleData[item][e.target.name] = Number(e.target.value);
  };
  //  background: '#FFF2CC'
  return (
    <>
      <tr>
        <td style={{ minWidth: '100px' }}>{state.user?.zone}</td>
        <td style={{ minWidth: '150px' }}>
          {nextyear.includes(item)
            ? `${item?.toUpperCase()} - ${+year + 1}`
            : `${item?.toUpperCase()} - ${year}`}
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="sales_order_total"
            value={moduleData?.[item]?.sales_order_total}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculatePercentage(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="dispatch_on_time"
            value={moduleData?.[item]?.dispatch_on_time}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculatePercentage(item);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="delayed"
            value={moduleData?.[item]?.delayed}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('delayed', 'TOTAL_delayed');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td
          style={{
            textAlign: 'center',
            background: '#FFF2CC',
            minWidth: '120px',
          }}
        >
          {moduleData?.[item]?.delivery_percentage}
        </td>
        {/*  */}

        {/*  */}
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="sales"
            value={moduleData?.[item]?.sales}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('sales', 'TOTAL_sales');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="store"
            value={moduleData?.[item]?.store}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('store', 'TOTAL_store');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="production"
            value={moduleData?.[item]?.production}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('production', 'TOTAL_production');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="qlty"
            value={moduleData?.[item]?.qlty}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('qlty', 'TOTAL_qlty');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="purchase"
            value={moduleData?.[item]?.purchase}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('purchase', 'TOTAL_purchase');
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={false}
            type="number"
            name="other"
            value={moduleData?.[item]?.other}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateTotal('other', 'TOTAL_other');
              setRender((prev) => !prev);
            }}
          />
        </td>

        {/*  */}
      </tr>
    </>
  );
};

export default TBodyRow;
