import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#ffd8a8',
  color: '#343a40',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    {
      text: 'Previous Months Open Feasibility ( Action Pending from NPD)',
      style: style1,
    },
    { text: 'New Feasibility ( Current Month )', style: style1 },
    { text: 'Total', style: style1 },
    { text: 'Open ( From NPD Team )', style: style1 },
    { text: 'Close ( From NPD Team )', style: style1 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Purchase ( From Forwarded Opp to Support) & Purchase ${year}-${
          year && year + 1
        }`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
