import TableHeaderHtmlCustom from '../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../components/ui';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#ffd8a8',
  color: '#343a40',
  minWidth: '100px',
  height: '50px',
  textTransform: 'uppercase',
};
const style2 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '80px',
  textTransform: 'uppercase',
};

const tableHeaderObj = [
  [
    { text: 'Order Cancellation details', style: style2, colspan: 5 },
    { text: 'Reason of Closure', style: style2, colspan: 6 },
  ],
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    { text: 'TOTAL SO', style: style1 },
    { text: 'ORDER CANCELED', style: style1 },
    { text: 'VALUE OF CANCELED ORDER', style: style1 },
    { text: 'Production ', style: style1 },
    { text: 'Purchase', style: style1 },
    { text: 'Sales', style: style1 },
    { text: 'Customer', style: style1 },
    { text: 'QA', style: style1 },
    { text: 'Others', style: style1 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Order Cancellation ${year}-${year && year + 1}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
