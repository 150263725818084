export const listConstant = {
  childHeader: 'RSM LIST',
};

export const createConstant = {
  childHeader: '',
  theadHeader: '',
};

export const apiConstant = {
  fetchUrl: '/api/HODandRSMroute',
  updaterurl: '/api/HODandRSMroute',
  updatyearTargetrurl: '/api/HODandRSMroute',
};

export const tilesConstant = {
  childHeaderText: '',
};

export const navigationConstant = {};
