const keyPropery = {
  total_so: null,
  order_cancelled: null,
  value_of_order_cancelled: null,
  production: '',
  purchase: '',
  sales: '',
  customer: '',
  qa: '',
  others: '',
};

export const initialModuleState = {
  april: { ...keyPropery },
  may: { ...keyPropery },
  june: { ...keyPropery },
  july: { ...keyPropery },
  august: { ...keyPropery },
  september: { ...keyPropery },
  october: { ...keyPropery },
  november: { ...keyPropery },
  december: { ...keyPropery },
  january: { ...keyPropery },
  february: { ...keyPropery },
  march: { ...keyPropery },
  TOTAL_total_so: null,
  TOTAL_order_cancelled: null,
  TOTAL_value_of_order_cancelled: null,
};
