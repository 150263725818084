import { TableInput } from '../../../components/common';
import TableHeaderHtmlCustom from '../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../components/ui';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    {
      text: 'New Customer ENQ (Online+ Offline) Vs Order conversion ratio',
      style: style1,
      colspan: 10,
    },
    { text: 'Reason for Open', style: style1, colspan: 4 },
    { text: 'Reason for Closure', style: style1, colspan: 3 },
    {
      text: 'Value Wise Analysis of New Customer Orders',
      style: style1,
      colspan: 10,
    },
  ],
  [
    { text: 'Zone', style: style2 },
    { text: 'Month', style: style2 },
    { text: 'Carry Forward Enquiries', style: style2 },
    { text: 'New for current month', style: style2 },
    {
      text: 'TOTAL NO. ENQ ( Total Open +New Recd )',
      style: style2,
    },
    {
      text: 'CONVERTED  ( From Carry Forward Enquiries )',
      style: style2,
    },
    {
      text: 'CONVERTED  ( From Current month Enquiries )',
      style: style2,
    },
    {
      text: 'Lost ( From Carry Forward Enquiries )',
      style: style2,
    },
    {
      text: 'Lost ( From Current month Enquiries )',
      style: style2,
    },
    {
      text: 'OPEN ( Carry Forward + Current Month )',
      style: style2,
    },
    {
      text: 'PENDING PRODUCTION',
      style: style2,
    },
    {
      text: 'PENDING PURCHASE',
      style: style2,
    },
    {
      text: 'PENDING NPD',
      style: style2,
    },
    {
      text: 'Pending from Customer',
      style: style2,
    },
    {
      text: 'Due to Higher Rates',
      style: style2,
    },
    {
      text: 'Customer not Responding',
      style: style2,
    },
    {
      text: 'Item not in Scope',
      style: style2,
    },
    {
      text: 'Current Month Target No',
      style: style2,
    },
    {
      text: "Current Month Achieved No's",
      style: style2,
    },
    {
      text: 'Current Month Target Value',
      style: style2,
    },
    {
      text: 'Current Month Achieved Value',
      style: style2,
    },
    {
      text: 'Cumulative Customer Target till now',
      style: style2,
    },
    {
      text: 'Cumulative Achieved till now',
      style: style2,
    },
    {
      text: 'Cumulative Expected Value for Month',
      style: style2,
    },
    {
      text: 'Cumulative Achieved value for Month',
      style: style2,
    },
    {
      text: 'Cumulative Expected Value Till Now',
      style: style2,
    },
    {
      text: 'Cumulative Achieved value Till Now',
      style: style2,
    },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={27}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Sales Plan - FY ${year}-${year && year + 1}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
