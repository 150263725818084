import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { allMonths } from '../../../../utils/helpers/allMonths';
import { useAuth } from '../../../../services/auth/AuthProvider';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../components/common/formatDate';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  isView: any;
  handleDelete: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  isView,
  handleDelete,
}: IProps) => {
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{item?.zone}</td>
        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="month"
            label=""
            value={item?.month}
            sx={{
              textAlign: 'center',
              width: '140px',
            }}
            onChange={(e) => {
              item.month = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            {allMonths?.map((month) => (
              <MenuItem value={month}>{month?.toLocaleUpperCase()}</MenuItem>
            ))}
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="complaint_no"
            value={item?.complaint_no || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="customer_name"
            value={item?.customer_name || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput isView={true} type="text" value={state?.user?.name} />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="part"
            value={item?.part || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="month_end_status"
            label=""
            value={item?.month_end_status}
            sx={{
              textAlign: 'center',
              width: '185px',
            }}
            onChange={(e) => {
              item.month_end_status = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Solved at Customer">Solved at Customer</MenuItem>
            <MenuItem value="Solved by Replacement">
              Solved by Replacement
            </MenuItem>
            <MenuItem value="Not Resolved">Not Resolved</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="latest_status"
            label=""
            value={item?.latest_status}
            sx={{
              textAlign: 'center',
              width: '185px',
            }}
            onChange={(e) => {
              item.latest_status = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Solved at Customer">Solved at Customer</MenuItem>
            <MenuItem value="Solved by Replacement">
              Solved by Replacement
            </MenuItem>
            <MenuItem value="Not Resolved">Not Resolved</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="visit"
            value={item?.visit || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="expense"
            value={item?.expense || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="premium_freight"
            value={item?.premium_freight || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            style={{ minWidth: '90px' }}
            name="customer_type"
            value={item?.customer_type || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <DeleteIconButton
            tooltipTitle="DELETE"
            onClick={() => {
              handleDelete(index);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
