import { GiCargoCrate, GiBuyCard } from 'react-icons/gi';
import { RiCustomerService2Fill } from 'react-icons/ri';
import {
  MdAnchor,
  MdSelfImprovement,
  MdOutlineSmsFailed,
  MdProductionQuantityLimits,
} from 'react-icons/md';
import { FcPlanner, FcCustomerSupport } from 'react-icons/fc';
import { TbBusinessplan } from 'react-icons/tb';
import { TbTruckReturn, TbStatusChange } from 'react-icons/tb';
import { VscFeedback } from 'react-icons/vsc';
import { BsFillCartXFill, BsGraphUpArrow } from 'react-icons/bs';
import { TbTruckDelivery } from 'react-icons/tb';
import { RenderTiles } from '../../components/common';

const SETilesObj = [
  {
    sNo: 1,
    link: '/se/seAnnulOverview',
    isLink: true,
    icon: <FcPlanner size="5rem" color="#099268" />,
    isIcon: true,
    p: 'Annual',
    span: 'SE SALES PLAN',
  },
  {
    sNo: 2,
    link: '/se/newCustomerEnquiryOrderConversion',
    isLink: true,
    icon: <RiCustomerService2Fill size="5rem" color="#1864ab" />,
    isIcon: true,
    p: 'Customer Enquiry',
    span: 'Vs Order Conversion',
  },
  {
    sNo: 3,
    link: '/se/premiumFreight',
    isLink: true,
    icon: <GiCargoCrate size="5rem" color="#343a40" />,
    isIcon: true,
    p: 'Premium',
    span: 'Freight',
  },
  {
    sNo: 4,
    link: '/se/kaizen',
    isLink: true,
    icon: <MdSelfImprovement size="5rem" color="#343a40" />,
    isIcon: true,
    p: 'Kaizen',
    span: '',
  },
  {
    sNo: 5,
    link: '/se/seTrainingRecordAndEffectiveness',
    isLink: true,
    icon: <MdAnchor size="5rem" color="#099268" />,
    isIcon: true,
    p: 'TRAINING RECORD',
    span: '& EFFECTIVENESS',
  },
  {
    sNo: 6,
    link: '/se/seCustomerFeedback',
    isLink: true,
    icon: <VscFeedback size="5rem" color="#099268" />,
    isIcon: true,
    p: 'CUSTOMER ',
    span: 'FEEDBACK',
  },
  {
    sNo: 7,
    link: '/se/orderCancellation',
    isLink: true,
    icon: <BsFillCartXFill size="5rem" color="#f03e3e" />,
    isIcon: true,
    p: 'ORDER ',
    span: 'CANCELLATION',
  },
  {
    sNo: 8,
    link: '/se/seMonthlyDeliveryFailure',
    isLink: true,
    icon: <TbTruckDelivery size="5rem" color="#f03e3e" />,
    isIcon: true,
    p: 'MONTHLY DELIVERY ',
    span: 'FAILURE',
  },
  {
    sNo: 9,
    link: '/se/seBizLostDueToPoorQlty',
    isLink: true,
    icon: <TbBusinessplan size="5rem" color="#f03e3e" />,
    isIcon: true,
    p: 'BUSINESS LOST',
    span: 'DUE TO POOR QLTY.',
  },
  {
    sNo: 10,
    link: '/se/thingsWentRightAndWrong',
    isLink: true,
    icon: <BsGraphUpArrow size="5rem" color="#099268" />,
    isIcon: true,
    p: 'THINGS WENT',
    span: 'RIGHT AND WRONG',
  },
  {
    sNo: 10,
    link: '/se/addComplaints',
    isLink: true,
    icon: <FcCustomerSupport size="5rem" color="#099268" />,
    isIcon: true,
    p: 'Customer',
    span: 'Complaints',
  },
  {
    sNo: 10,
    link: '/se/addMaterialReturnValue',
    isLink: true,
    icon: <TbTruckReturn size="5rem" color="#343a40" />,
    isIcon: true,
    p: 'Material',
    span: 'Return Value',
  },
  {
    sNo: 10,
    link: '/se/addCommitmentFailure',
    isLink: true,
    icon: <MdOutlineSmsFailed size="5rem" color="#f03e3e" />,
    isIcon: true,
    p: 'Commitment',
    span: 'Failure',
  },
  {
    sNo: 10,
    link: '/se/addProductionFeasibility',
    isLink: true,
    icon: <MdProductionQuantityLimits size="5rem" color="#1864ab" />,
    isIcon: true,
    p: 'Production',
    span: 'Feasibility',
  },
  {
    sNo: 10,
    link: '/se/addPurchaseFeasibility',
    isLink: true,
    icon: <GiBuyCard size="5rem" color="#343a40" />,
    isIcon: true,
    p: 'Purchase',
    span: 'Feasibility',
  },
  {
    sNo: 10,
    link: '/se/addActivePassiveStatus',
    isLink: true,
    icon: <TbStatusChange size="5rem" color="#087f5b" />,
    isIcon: true,
    p: 'Active Passive',
    span: 'status',
  },
];

const SEHome = () => {
  return <RenderTiles tilesObj={SETilesObj} />;
};

export default SEHome;
