import { useEffect, useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { allMonths } from '../../../../utils/helpers/allMonths';
import { useAuth } from '../../../../services/auth/AuthProvider';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../components/common/formatDate';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  item: any;
  getFullYear: any;
  isView: any;
  handleDelete: any;
  year: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  isView,
  handleDelete,
}: IProps) => {
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{item?.zone}</td>
        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="month"
            label=""
            value={item?.month}
            sx={{
              textAlign: 'center',
              width: '140px',
            }}
            onChange={(e) => {
              item.month = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            {allMonths?.map((month) => (
              <MenuItem value={month}>{month?.toLocaleUpperCase()}</MenuItem>
            ))}
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="customer_code"
            value={item?.customer_code}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput isView={true} type="text" value={state?.user?.name} />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="customer_name"
            value={item?.customer_name}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="report_last_invoice"
            value={item?.report_last_invoice}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center', minWidth: '150px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.report_date = moment(date);
              setRowRender((prev) => !prev);
            }}
            value={item?.report_date && moment(item?.report_date)}
          />
        </td>

        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="start_of_month_status"
            label=""
            value={item?.start_of_month_status}
            sx={{
              textAlign: 'center',
              width: '160px',
            }}
            onChange={(e) => {
              item.start_of_month_status = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="PASSIVE">PASSIVE</MenuItem>
            <MenuItem value="DORMANT">DORMANT</MenuItem>
            <MenuItem value="NO INVOICE">NO INVOICE</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="current_last_invoice"
            style={{ minWidth: '113px' }}
            value={item?.current_last_invoice}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'start' }}>
          <CustomSelect
            name="end_of_month_status"
            label=""
            value={item?.end_of_month_status}
            sx={{
              textAlign: 'center',
              width: '160px',
            }}
            onChange={(e) => {
              item.end_of_month_status = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="PASSIVE">PASSIVE</MenuItem>
            <MenuItem value="DORMANT">DORMANT</MenuItem>
            <MenuItem value="NO INVOICE">NO INVOICE</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="value"
            style={{ minWidth: '113px' }}
            value={item?.value}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>

        <td style={{ textAlign: 'center' }}>
          <DeleteIconButton
            tooltipTitle="DELETE"
            onClick={() => {
              handleDelete(index);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
