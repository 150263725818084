import { TableInput } from '../../../components/common';
import { TableCompHead } from '../../../components/ui';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Sales Plan - FY ${year}-${year && year + 1}`}
      />
      <tr>
        <th colSpan={3}>YEAR TARGET</th>
        <th
          style={{
            backgroundColor: '#fff9db',
          }}
          colSpan={7}
        >
          {moduleData?.user_annual_target}
        </th>
      </tr>
      <tr>
        <th colSpan={10}>MONTH TARGET</th>
      </tr>
      <tr
        style={{
          position: 'sticky',
          top: '0rem',
          zIndex: 1,
        }}
      >
        <th style={style1}>Zone</th>
        <th style={style1}>Month</th>
        <th style={style1}>RSM</th>
        <th style={style1}>Target</th>
        <th style={style1}>MTD Actual</th>
        <th style={style1}>% Ach</th>

        <th style={style1}>MTD Target pending </th>
        <th style={style1}>YTD Target</th>
        <th style={style1}>YTD Actual</th>
        <th style={style1}>% Ach</th>
      </tr>
    </thead>
  );
};

export default THead;
