import { TableInput } from '../../../components/common';
import TableHeaderHtmlCustom from '../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../components/ui';
import { useQuery } from '../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const style1 = {
  background: '#FFF2CC',
  color: '#343a40',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'Zone', style: style1 },
    { text: 'Month', style: style1 },
    { text: 'Date', style: { ...style1, width: '200px' } },
    { text: 'Zone', style: style1 },
    { text: 'Customer Name', style: style1 },
    { text: 'Invoice No.', style: style1 },
    { text: 'Amount', style: style1 },
    { text: 'Concerned Department', style: style1 },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={1}
        labels={[]}
        values={[]}
        headingStyle={{}}
        heading={`Premium Freight ${year}-${year && year + 1}`}
      />

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
