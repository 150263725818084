const keyPropery = {
  carry_forward_enquiries: null,
  new_for_current_month: null,
  total_no_enq_total_open_new_recd: null,
  converted_from_carry_forward_enquiries: null,
  converted_from_current_month_enquiries: null,
  lost_from_carry_forward_enquiries: null,
  lost_from_current_month_enquiries: null,
  open_carry_forward_current_month: null,
  pending_production: null,
  pending_purchase: null,
  pending_npd: null,
  pending_from_customer: null,
  due_to_higher_rates: null,
  customer_not_responding: null,
  item_not_in_scope: null,
  current_month_target_no: null,
  current_month_achieved_nos: null,
  current_month_target_value: null,
  current_month_achieved_value: null,
  cumulative_customer_target_till_now: null,
  cumulative_achieved_till_now: null,
  cumulative_expected_value_for_month: null,
  cumulative_achieved_value_for_month: null,
  cumulative_expected_value_till_now: null,
  cumulative_achieved_value_till_now: null,
};

export const initialModuleState = {
  april: { ...keyPropery },
  may: { ...keyPropery },
  june: { ...keyPropery },
  july: { ...keyPropery },
  august: { ...keyPropery },
  september: { ...keyPropery },
  october: { ...keyPropery },
  november: { ...keyPropery },
  december: { ...keyPropery },
  january: { ...keyPropery },
  february: { ...keyPropery },
  march: { ...keyPropery },

  total_carry_forward_enquiries: null,
  total_new_for_current_month: null,
  total_total_no_enq_total_open_new_recd: null,
  total_converted_from_carry_forward_enquiries: null,
  total_converted_from_current_month_enquiries: null,
  total_lost_from_carry_forward_enquiries: null,
  total_lost_from_current_month_enquiries: null,
  total_open_carry_forward_current_month: null,
  total_pending_production: null,
  total_pending_purchase: null,
  total_pending_npd: null,
  total_pending_from_customer: null,
  total_due_to_higher_rates: null,
  total_customer_not_responding: null,
  total_item_not_in_scope: null,
  total_current_month_target_no: null,
  total_current_month_achieved_nos: null,
  total_current_month_target_value: null,
  total_current_month_achieved_value: null,
  total_cumulative_customer_target_till_now: null,
  total_cumulative_achieved_till_now: null,
  total_cumulative_expected_value_for_month: null,
  total_cumulative_achieved_value_for_month: null,
  total_cumulative_expected_value_till_now: null,
  total_cumulative_achieved_value_till_now: null,
};
