const keyPropery = {
  date: null,
  training_name: null,
  training_attendees: null,
  training_faculty: null,
  benefits_from_training: null,
};

export const initialModuleState = {
  april: { ...keyPropery },
  may: { ...keyPropery },
  june: { ...keyPropery },
  july: { ...keyPropery },
  august: { ...keyPropery },
  september: { ...keyPropery },
  october: { ...keyPropery },
  november: { ...keyPropery },
  december: { ...keyPropery },
  january: { ...keyPropery },
  february: { ...keyPropery },
  march: { ...keyPropery },
};
