import mushinlabFullLogo from '../../assets/images/ARC Logo.png';
import mushinlabHalfLogo from '../../assets/images/WHITE.png';
import logo from '../../assets/images/DP Mushin Labs.png';

export const proxy_img: string = 'https://proxy.aqua.mushinlabs.com/proxy/';

interface ImagesType {
  mushinlabFullLogo: string;
  mushinlabHalfLogo: string;
  companyImg: string;
  moduleCompanyImgHeader: string;
  companyLogo: string;
}

export const images: ImagesType = {
  mushinlabFullLogo: mushinlabFullLogo,
  mushinlabHalfLogo: mushinlabHalfLogo,
  companyImg: `${proxy_img}https://mushin-labs.s3.ap-south-1.amazonaws.com/SL-Fasteners/SL+Logo.png`,
  moduleCompanyImgHeader: `${proxy_img}https://mushin-labs.s3.ap-south-1.amazonaws.com/SL-Fasteners/SL+Logo.png`,
  companyLogo: logo,
};

export const textInputs: {
  companyName: string;
} = {
  companyName: 'SLF SALES',
};
