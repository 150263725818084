import HODHome from '../pages/HomeHod/HodHome';
import EmployeeList from '../../src/pages/employee/List';
import { Profile } from '../pages/profile';
import { HodAnnulOverview } from '../pages/annualOverviewHod';
import { HodSetTargetList } from '../pages/hodSettarget';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const IndexHOD: routes[] = [
  {
    path: 'home',
    element: <HODHome />,
  },
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'masterlistEmployee',
    element: <EmployeeList />,
  },
  {
    path: 'hodSetTargetList',
    element: <HodSetTargetList />,
  },
  {
    path: 'hodAnnulOverview',
    element: <HodAnnulOverview />,
  },
];
