import { HiDocumentReport } from 'react-icons/hi';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { FcNegativeDynamic, FcPositiveDynamic } from 'react-icons/fc';
import { useAuth } from '../../services/auth/AuthProvider';

const ThingsWentRightAndWrong = () => {
  const { state } = useAuth();

  const tiles = [
    {
      sNo: 1,
      link: `/${
        state?.user?.userType === state.IS.RSM ? 'rsm' : 'se'
      }/rightThings`,
      isLink: true,
      icon: <FcPositiveDynamic size="5rem" color="#003566" />,
      p: 'RIGHT',
      span: 'THINGS',
      isIcon: true,
    },
    {
      sNo: 2,
      link: `/${
        state?.user?.userType === state.IS.RSM ? 'rsm' : 'se'
      }/wrongThings`,
      isLink: true,
      icon: <FcNegativeDynamic size="5rem" color="#003566" />,
      p: 'WRONG',
      span: 'THINGS',
      isIcon: true,
    },
  ];

  return (
    <>
      <ChildHeader text="THINGS WENT RIGHT AND WRONG" />
      <RenderTiles
        height="100%"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default ThingsWentRightAndWrong;
