import { allMonths } from '../../utils/helpers/allMonths';

export class ModuleDataHandler {
  moduleData: any;

  constructor(moduleData: any) {
    this.moduleData = moduleData;
  }

  // all totals function
  calculateTotal(key_name: any, update_key_name: any) {
    const total = allMonths.reduce((accumulator: any, month: any) => {
      if (
        this.moduleData.hasOwnProperty(month) &&
        this.moduleData[month].hasOwnProperty(key_name)
      ) {
        return (+accumulator || 0) + +this.moduleData[month][key_name];
      }
      return accumulator;
    }, 0);
    this.moduleData[update_key_name] = total;
  }
}
